import React, { useState, useEffect } from 'react';
import apiMethods from 'core/methods';
import { API } from 'htcore';
import { BOOKING_STATUS, PAYMENT_STATUS, PAYMENT_TYPE } from 'htcore/enum';
import { date } from 'legacy';
import BookingsList from 'pages/bookings/bookings-list';
import { serializeRequest } from '../../serializer';

const AttentionRequiredBookings = () => {
    const [bookings, setBookings] = useState(false);

    const fetchBookings = () => {
        setBookings(null);
        API.get({
            url: apiMethods.bookingsList,
            body: serializeRequest(
                {
                    rangeDeadline: [new Date(), date.addDay(new Date(), 4)],
                    statuses: [
                        BOOKING_STATUS.Confirmed,
                        BOOKING_STATUS.Pending,
                        BOOKING_STATUS.ManualCorrectionNeeded,
                        BOOKING_STATUS.PendingCancellation,
                    ],
                    paymentStatuses: [PAYMENT_STATUS.NotPaid],
                    paymentTypes: [PAYMENT_TYPE.Offline],
                },
                100,
                0
            ),
            success: setBookings,
        });
    };

    useEffect(() => {
        fetchBookings();
    }, []);

    return <BookingsList bookings={bookings} />;
};

export default AttentionRequiredBookings;

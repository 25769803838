import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { useTitle } from 'htcore';
import { Menu, PageHeader } from 'antd';
import RoomMappingSettings from './room-mapping-settings';
import RoomMappingHistory from './room-mapping-history';

const navigationItems = [
    {
        label: <Link to="./">Settings</Link>,
        key: '',
    },
    {
        label: <Link to="./history">Change History</Link>,
        key: 'history',
    },
];

const RoomMappingPage = () => {
    useTitle('Room Mapping');

    const location = useLocation();

    return (
        <>
            <PageHeader title="Room Mapping" />
            <Menu
                mode="horizontal"
                items={navigationItems}
                selectedKeys={location.pathname.split('/')[2] || ''}
            />
            <Routes>
                <Route path="/" element={<RoomMappingSettings />} />
                <Route path="/history" element={<RoomMappingHistory />} />
            </Routes>
        </>
    );
};

export default RoomMappingPage;

import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import ActiveAgencyForm from './active-agency-form';
import AgencySelector from 'components/selectors/agency-selector';
import AgentSelector from 'components/selectors/agent-selector';

export const AgenciesTypes = {
    Active: 'active',
    Surrogate: 'surrogate',
};

const AgencySelectionForm = ({ creation, form }) => {
    const isDev =
        window.location.hostname === 'localhost' || window.location.hostname === 'manage-dev.happytravel.com';

    return (
        <>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="agencyType"
                        label="Agency Type"
                        rules={[{ required: true, message: 'Please select' }]}
                    >
                        <Select
                            placeholder="Select"
                            onChange={() => {
                                form.setFieldValue('surrogateAgencyId', null);
                                form.setFieldValue('agentId', null);
                                form.setFieldValue('agencyId', null);
                            }}
                        >
                            <Option value={AgenciesTypes.Active}>Active</Option>
                            <Option value={AgenciesTypes.Surrogate}>Surrogate</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => {
                    const agencyType = getFieldValue('agencyType');
                    if (agencyType === AgenciesTypes.Active) {
                        return (
                            <ActiveAgencyForm
                                creation={creation}
                                getFieldValue={getFieldValue}
                                setFieldValue={setFieldValue}
                            />
                        );
                    }

                    if (agencyType === AgenciesTypes.Surrogate) {
                        return (
                            <Row size="large">
                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Form.Item
                                        name="surrogateAgencyId"
                                        label="Surrogate Agency"
                                        rules={[{ required: true, message: 'Please select' }]}
                                    >
                                        <AgencySelector
                                            disabled={!creation || !!getFieldValue('selectedItineraryNumber')}
                                            filter="isSurrogate eq true and (verificationState eq 'FullAccess')"
                                            placeholder="Select"
                                            onlyActiveAgencies
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12} style={{ paddingRight: 30 }}>
                                    <Form.Item
                                        name="agentId"
                                        label="Agent"
                                        rules={[{ required: true, message: 'Please select' }]}
                                    >
                                        <AgentSelector agencyId={isDev ? 890 : 3} placeholder="Select" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        );
                    }
                }}
            </Form.Item>
        </>
    );
};

export default AgencySelectionForm;

const apiSunpuMethods = {
    suppliers: `/suppliers`,
    adminSuppliers: `/admin/suppliers`,
    suppliersData: `/admin/suppliersData`,
    supplierData: (code) => `/admin/suppliersData/${code}`,
    supplier: (code) => `/admin/suppliers/${code}`,
    supplierHistory: (code) => `/admin/suppliers/${code}/history`,
    supplierEnableState: (code) => `/admin/suppliers/${code}/enable-state`,
    suppliersPriorities: `/admin/supplier-priorities`,
    suppliersTypes: `/admin/suppliers/available-types`,
    supplierDataTechnicalDetails: (code) => `/admin/suppliersData/${code}/technical-details`,
    supplierDataPaymentDetails: (code) => `/admin/suppliersData/${code}/payment`,
    supplierDataContactDetails: (code) => `/admin/suppliersData/${code}/contact-details`,
    supplierDataSupplierDetails: (code) => `/admin/suppliersData/${code}/supplier-details`,
    supplierDataCacheSettings: (code) => `/admin/suppliersData/${code}/cache-settings`,
    supplierContractFiles: (code) => `/admin/suppliersData/${code}/contract-file`,
    supplierContractFile: (code, contractFileId) =>
        `/admin/suppliersData/${code}/contract-file/${contractFileId}`,
};

export default apiSunpuMethods;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Tag } from 'antd';
import { API } from 'htcore';
import { date } from 'legacy';
import Table from 'components/table';
import apiMethods from 'core/methods';

const AgencyAgents = ({ agency }) => {
    const [list, setList] = useState(null);

    useEffect(() => {
        API.get({
            url: apiMethods.agencyAgents(agency.id),
            success: (list) => {
                setList(list.reverse());
            },
        });
    }, [agency]);

    return (
        <Table
            list={list}
            columns={[
                {
                    header: 'ID',
                    cell: (cell) => (
                        <Link to={`/agency/${agency.id}/agents/${cell.agentId}/bookings/all`}>
                            {cell.agentId}
                        </Link>
                    ),
                },
                {
                    header: 'Name',
                    cell: (cell) => (
                        <>
                            <Link to={`/agency/${agency.id}/agents/${cell.agentId}/bookings/all`}>
                                {cell.name}
                            </Link>
                            {cell.isApiClient && (
                                <>
                                    <Tag color="#59BA71" style={{ marginLeft: 6 }}>
                                        API Client
                                    </Tag>
                                    <span>{cell.qps} QPS</span>
                                </>
                            )}
                        </>
                    ),
                },
                {
                    header: 'Active',
                    cell: (cell) => (cell.isActive ? 'Yes' : 'No'),
                },
                {
                    header: 'Created',
                    cell: (cell) => date.format.a(cell.created * 1000),
                },
                {
                    header: 'Markup Settings',
                    cell: (cell) => cell.markupFormula || 'None',
                },
            ]}
            textEmptyResult="No agents found"
            textEmptyList="No agents found (empty)"
            searches={(v) => [String(v.agentId), v.name]}
            rowKey="agentId"
            CustomFilter={
                <Link to="../agent-invite">
                    <Button type="primary">Invite New Agent</Button>
                </Link>
            }
        />
    );
};

export default AgencyAgents;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Typography, Row, Col, Form } from 'antd';
import { notifications } from 'components/notification';
import SearchTable from './search-table';
import RestrictionsSaveModal from './restrictions-save-modal';
import SupplierLocalitiesTable from './supplier-localities-table';

const { Title } = Typography;

const mapSuppliersDataToView = (data) =>
    data.map((item) => {
        const isExcludedType = item.excludedLocalityHtIds?.length;

        return {
            ...item,
            type: isExcludedType ? 'except' : 'include',
            localityHtIds: isExcludedType ? item.excludedLocalityHtIds : item.includedLocalityHtIds,
        };
    });

const mapDataToPayload = (data) =>
    Object.values(data).map(({ isAvailable, supplierCode, type, localityHtIds }) => {
        const isInclude = type === 'include';
        return {
            isAvailable,
            supplierCode,
            includedLocalityHtIds: isInclude ? localityHtIds : [],
            excludedLocalityHtIds: !isInclude ? localityHtIds : [],
        };
    });

const isEqualStringArrays = (firstArray, secondArray) =>
    JSON.stringify(firstArray.sort()) === JSON.stringify(secondArray.sort());

const removeUnchangedData = (newData, oldData) =>
    newData.filter((item, index) => {
        const oldDataItem = oldData[index];
        if (item.isAvailable !== oldDataItem.isAvailable) return true;

        return (
            !isEqualStringArrays(item.excludedLocalityHtIds, oldDataItem.excludedLocalityHtIds) ||
            !isEqualStringArrays(item.includedLocalityHtIds, oldDataItem.includedLocalityHtIds)
        );
    });

const RestrictionsCountries = () => {
    const [form] = Form.useForm();

    const [countriesList, setCountriesList] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [suppliersList, setSuppliersList] = useState(null);
    const [suppliersLoading, setSuppliersLoading] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const { code } = useParams();

    useEffect(() => {
        API.get({
            url: apiMethods.allCountries(),
            success: (result) => setCountriesList(result.map((item) => ({ ...item, id: item.code }))),
        });
    }, []);

    const getSuppliers = (id) => {
        if (id) {
            setSuppliersLoading(true);
            API.get({
                url: apiMethods.countriesRestrictions(id),
                success: (result) => {
                    const data = mapSuppliersDataToView(result);

                    setSuppliersList(data);
                    form.setFieldsValue(data);
                },
                after: () => setSuppliersLoading(false),
            });
        }
    };

    const onSelectRow = ({ id }) => {
        setSelectedCountry(id);
        getSuppliers(id);
    };

    useEffect(() => {
        if (code) onSelectRow({ id: code });
    }, []);

    const saveChanges = (keepRestrictions) => {
        API.post({
            url: apiMethods.countriesRestrictions(selectedCountry, keepRestrictions),
            body: removeUnchangedData(mapDataToPayload(form.getFieldsValue(true)), suppliersList),
            success: () => {
                setOpenModal(false);
                notifications('Saved!', 'success');
            },
        });
    };

    return (
        <>
            <Row justify="space-between">
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Countries
                </Title>

                <Button onClick={() => setOpenModal(true)} disabled={!selectedCountry}>
                    Save Changes
                </Button>
            </Row>

            <Row gutter={30}>
                <Col span={12}>
                    <SearchTable
                        loading={countriesList === null}
                        selectedRow={selectedCountry}
                        tableTitle={`Countries List (${countriesList?.length || 0})`}
                        list={countriesList}
                        onRow={(record) => ({ onClick: () => onSelectRow(record) })}
                    />
                </Col>
                <Col span={12}>
                    <SupplierLocalitiesTable
                        suppliersLoading={suppliersLoading}
                        id={selectedCountry}
                        form={form}
                        suppliersList={suppliersList}
                    />
                </Col>
            </Row>

            <RestrictionsSaveModal
                open={openModal}
                keepButtonText="Keep restriction on accommodation level"
                questionText="Do you want to keep the previously added restrictions at the accommodation level?"
                saveChanges={saveChanges}
                onClose={() => setOpenModal(false)}
            />
        </>
    );
};

export default RestrictionsCountries;

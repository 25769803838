import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Form } from 'antd';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import AgencyDetailsSurrogateForm from '../agency-parts/details/agency-details-surrogate-form';

const SurrogateAgencyCreatePage = () => {
    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false);
    const [agencyDetailsValues, setAgencyDetailsValues] = useState({});

    const [agencyForm] = Form.useForm();

    const navigate = useNavigate();

    const openSaveChangesModal = (values) => {
        setSaveChangesModalVisible(true);
        setAgencyDetailsValues(values);
    };

    const submit = (values) => {
        API.post({
            url: apiMethods.agencyCreate(),
            body: {
                ...values,
                isSurrogateAgency: true,
            },
            success: (newAgency) => {
                notifications('Surrogate Agency Created!', 'success');
                navigate(`/surrogate-agencies/${newAgency.id}/details-surrogate`);
            },
        });
    };

    return (
        <>
            <Form form={agencyForm} onFinish={openSaveChangesModal} layout="vertical">
                <AgencyDetailsSurrogateForm isCreation />

                <Button htmlType="submit" type="primary">
                    Add
                </Button>
            </Form>

            <ConfirmationSimpleModal
                visible={saveChangesModalVisible}
                onCancel={() => setSaveChangesModalVisible(false)}
                headerText="Confirmation"
                submitText="Confirm"
                onConfirm={() => submit(agencyDetailsValues)}
            >
                Are you sure that you want to create new Surrogate Agency?
            </ConfirmationSimpleModal>
        </>
    );
};

export default SurrogateAgencyCreatePage;

import React from 'react';
import { Link } from 'react-router-dom';

export const columns = () => [
    {
        header: 'ID',
        cell: ({ id }) => id,
    },
    {
        header: 'Name',
        cell: ({ name, id }) => (
            <Link target="_blank" to={`/agency/${id}/agents`}>
                {name}
            </Link>
        ),
    },
    {
        header: 'Supplier Settings',
        cell: ({ isSupplierEnabled }) => (isSupplierEnabled ? 'Active' : 'Inactive'),
    },
];

import React from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMethods from 'core/methods';

const AdminSelector = ({ isOffline, ...props }) => {
    const optionsGenerator = (list) => {
        return list
            .filter((item) => item.id !== 1)
            .map((item) => ({
                value: item.id,
                isAgent: item.isAgent,
                label: `${isOffline ? `${item.isAgent ? 'Agent ' : 'Admin '}` : ''} ${item.firstName} ${
                    item.lastName
                }`,
            }));
    };

    const { options, loading } = useSelector(
        { url: isOffline ? apiMethods.adminsOffline : apiMethods.administrators },
        optionsGenerator
    );

    return <EntitySelector placeholder="Anyone" {...props} options={options} loading={loading} />;
};

export default AdminSelector;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import { message, Space, Typography } from 'antd';
import Table from 'components/table';
import DownloadHotelsList from './download-hotels-list';
import { notifications } from 'components/notification';
import UploadTemplate from './upload-template';

const { Title } = Typography;

const columns = (updateData, downloadByTemplate) => [
    {
        header: 'ID and Name',
        cell: ({ agentId, agentName }) => `${agentId} ${agentName}`,
    },

    {
        header: 'Enabled Suppliers',
        cell: ({ suppliers }) => suppliers.join(', '),
    },

    {
        header: 'Number of available accommodations',
        cell: ({ agentId, totalCounts, accommodationListCounts }) => {
            const availableNumber =
                totalCounts.availableAccommodationsCount - totalCounts.unavailableAccommodationsCount;
            const availableByTemplate =
                (accommodationListCounts?.availableAccommodationsCount || 0) -
                (accommodationListCounts?.unavailableAccommodationsCount || 0);

            return (
                <div style={{ minWidth: 190 }}>
                    <div>
                        <b>Total: </b>
                        <Link to={`./${agentId}`}>{totalCounts.availableAccommodationsCount}</Link>
                        <br />

                        <span style={{ fontSize: 12, color: '#777777' }}>
                            {availableNumber} available, {totalCounts.unavailableAccommodationsCount}{' '}
                            unavailable
                        </span>
                    </div>

                    {accommodationListCounts && (
                        <div>
                            <div onClick={() => downloadByTemplate(agentId)}>
                                <b>By Template: </b>

                                <span style={{ color: '#1267fb', cursor: 'pointer' }}>
                                    {accommodationListCounts.availableAccommodationsCount}
                                </span>
                            </div>
                            <span style={{ fontSize: 12, color: '#777777' }}>
                                {availableByTemplate} available,{' '}
                                {accommodationListCounts.unavailableAccommodationsCount} unavailable
                            </span>
                        </div>
                    )}
                </div>
            );
        },
    },

    {
        header: 'Actions',
        cell: ({ agentId, accommodationListCounts }) => (
            <Space>
                <DownloadHotelsList agentId={agentId} />
                <UploadTemplate
                    agentId={agentId}
                    updateData={updateData}
                    isHasTemplate={!!accommodationListCounts}
                />
            </Space>
        ),
    },
];

const HotelsList = () => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    const getData = () => {
        setLoading(true);

        API.get({
            url: apiMethods.hotels(id),
            success: setList,
            after: () => setLoading(false),
        });
    };

    const download = (res, hideMessage, agentId) => {
        if (res.status !== 200) {
            hideMessage();
            notifications("Couldn't download", 'warning');
        } else {
            res.blob().then((blobby) => {
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = `hotels-agency-${id}-agent-${agentId}.csv`;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                hideMessage();
                message.success('Downloaded');
            });
        }
    };

    const downloadByTemplate = (agentId) => {
        const hideMessage = message.loading(' Downloading...', 0);

        API.get({
            url: apiMethods.hotelsTemplate(id, agentId),
            response: (result) => download(result, hideMessage, agentId),
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Hotels List
            </Title>

            <Table list={list} columns={columns(getData, downloadByTemplate)} />
        </>
    );
};

export default HotelsList;

import React from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Button, Col, Form, Row } from 'antd';
import UnmappedAccommodationSelector from 'components/selectors/unmapped-accommodation-selector';
import { notifications } from 'components/notification';

const regex = /^[a-zA-Z0-9\s]*$/;

const getIsValidName = (name) => {
    if (!name) return true;
    return name.match(regex);
};

const UnmappedAccommodation = ({ getFieldValue, setFieldValue }) => {
    const unmappedHtId = getFieldValue('unmappedHtId');

    const addNewAccommodation = () => {
        API.post({
            url: apiMethods.unmappedAccommodation,
            body: { name: unmappedHtId.label },
            success: ({ uHtId, name }) => {
                setFieldValue('unmappedHtId', { label: name, value: uHtId });
                notifications('New Unmapped Accommodation Created!', 'success');
            },
        });
    };

    const validateAccommodation = () => {
        if (!unmappedHtId) return Promise.resolve();
        if (unmappedHtId.value) return Promise.resolve();

        return Promise.reject(new Error('You should Add New Unmapped Accommodation'));
    };

    return (
        <>
            <Row>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="unmappedHtId"
                        label="Unmapped Accomodation"
                        rules={[{ required: true }, { validator: validateAccommodation }]}
                        style={{ marginBottom: 0 }}
                    >
                        <UnmappedAccommodationSelector
                            setFieldValue={(newValue) => setFieldValue('unmappedHtId', newValue)}
                            getFieldValue={() => getFieldValue('unmappedHtId')}
                            placeholder="Please enter Unmapped Accommodation name"
                            onChange={(_, data) => setFieldValue('unmappedHtId', data)}
                        />
                    </Form.Item>
                    {!getIsValidName(unmappedHtId?.label) && unmappedHtId?.value === null && (
                        <span style={{ color: '#ff4d4f' }}>Only English alphabet is allowed</span>
                    )}
                </Col>
            </Row>

            <Row>
                <Button
                    disabled={unmappedHtId?.value !== null || !getIsValidName(unmappedHtId?.label)}
                    style={{ margin: '20px 0px' }}
                    type="primary"
                    onClick={addNewAccommodation}
                >
                    Add New Unmapped Accommodation
                </Button>
            </Row>
        </>
    );
};

export default UnmappedAccommodation;

import { date } from 'legacy';

const safeString = (val) => val.replace(/'/g, "''");

export const serializeRequest = (values, top, skip) => {
    const filters = [];

    if (values.isSurrogate) {
        filters.push('isSurrogate eq true');
    } else {
        filters.push('isSurrogate eq false');
    }

    if (values.id) {
        filters.push(`id eq ${values.id}`);
    }

    if (values.rangeCreation) {
        filters.push(
            `created ge ${date.format.api(values.rangeCreation[0])} and created lt ${date.format.api(
                date.addDay(values.rangeCreation[1], 1)
            )}`
        );
    }

    if (values.isActive) {
        filters.push(`isActive eq true`);
    }

    if (values.verificationState?.length) {
        filters.push(
            '(' +
                values.verificationState.map((state) => `verificationState eq '${state}'`).join(' or ') +
                ')'
        );
    }

    if (values.nameQuery) {
        filters.push(`contains(tolower(name),tolower('${safeString(values.nameQuery)}'))`);
    }

    if (values.countryNameQuery) {
        filters.push(`contains(tolower(countryName),tolower('${safeString(values.countryNameQuery)}'))`);
    }

    if (values.cityQuery) {
        filters.push(`contains(tolower(city),tolower('${safeString(values.cityQuery)}'))`);
    }

    if (values.accountManager) {
        filters.push(`accountManagerId eq ${values.accountManager}`);
    }

    return {
        ...(filters.length
            ? {
                  $filter: filters.join(' and '),
              }
            : {}),
        $orderBy: 'Created desc',
        $top: top,
        $skip: skip,
    };
};

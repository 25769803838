import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Button, Menu, PageHeader } from 'antd';
import BookingSearchPage from '../booking-search';
import BookingsService from '../tabs/service/bookings-service';

const items = [
    { label: <Link to="./">Offline Bookings</Link>, key: '' },
    { label: <Link to="./service">Service Bookings</Link>, key: 'service' },
];

const OfflineBookingsSearch = ({ bookingSearchForm, serviceSearchForm }) => {
    const location = useLocation();

    return (
        <>
            <PageHeader
                title="Offline Bookings"
                extra={
                    <Link to="./booking/create">
                        <Button type="primary">Create a New Offline Booking</Button>
                    </Link>
                }
            />

            <Menu mode="horizontal" items={items} selectedKeys={location.pathname.split('/')[2] || ''} />

            <Routes>
                <Route
                    path="/"
                    element={<BookingSearchPage bookingSearchForm={bookingSearchForm} isOfflineBookings />}
                />
                <Route path="/service" element={<BookingsService searchForm={serviceSearchForm} />} />
            </Routes>
        </>
    );
};

export default OfflineBookingsSearch;

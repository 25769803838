import React, { useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import AgencyContract from '../agency-contract';
import { Button, Form } from 'antd';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import AgencyDetailsForm from './agency-details-form';

const AgencyDetails = ({ agency, loadAgency }) => {
    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false);
    const [agencyDetailsValues, setAgencyDetailsValues] = useState({});

    const [agencyForm] = Form.useForm();

    const openSaveChangesModal = (values) => {
        setSaveChangesModalVisible(true);
        setAgencyDetailsValues(values);
    };

    const submit = (values) => {
        API.put({
            url: apiMethods.agency(agency.id),
            body: {
                ...agency,
                ...values,
                ...(values?.nonTRN && { taxRegistrationNumber: '' }),
                ...(values.creditLimit?.amount !== undefined
                    ? {
                          creditLimit: {
                              amount: values.creditLimit.amount,
                              currency: 'USD',
                          },
                      }
                    : {}),
            },
            success: () => {
                notifications('Saved', 'success');
                loadAgency();
            },
            after: () => {
                setSaveChangesModalVisible(false);
            },
        });
    };

    if (!agency) {
        return <Loader />;
    }

    return (
        <>
            <Form
                form={agencyForm}
                initialValues={{
                    ...agency,
                    nonTRN: !Boolean(agency.taxRegistrationNumber),
                }}
                onFinish={openSaveChangesModal}
                layout="vertical"
            >
                <AgencyDetailsForm isEdit agency={agency} />

                <Button htmlType="submit">Save Changes</Button>
            </Form>
            <AgencyContract agency={agency} loadAgency={loadAgency} />
            <ConfirmationSimpleModal
                visible={saveChangesModalVisible}
                onCancel={() => setSaveChangesModalVisible(false)}
                headerText="Are you sure?"
                submitText="Confirm"
                onConfirm={() => submit(agencyDetailsValues)}
            />
        </>
    );
};

export default AgencyDetails;

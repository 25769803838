import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import { Button, Upload } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { notifications } from 'components/notification';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';

const UploadTemplate = ({ agentId, isHasTemplate, updateData }) => {
    const { id } = useParams();

    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const uploadFile = (file) => {
        const data = new FormData();

        data.append('file', file);

        API.post({
            url: apiMethods.hotelsTemplate(id, agentId),
            formDataBody: data,
            success: () => {
                updateData();
                notifications('Template Uploaded!', 'success');
            },
        });
    };

    const removeTemplate = () => {
        setIsLoading(true);
        API.delete({
            url: apiMethods.hotelsTemplate(id, agentId),
            success: () => {
                setIsLoading(false);
                setIsOpenConfirm(false);
                updateData();
                notifications('Template Removed!', 'success');
            },
        });
    };

    return (
        <>
            {isHasTemplate ? (
                <Button danger icon={<DownloadOutlined />} onClick={() => setIsOpenConfirm(true)}>
                    Remove Template
                </Button>
            ) : (
                <Upload
                    action={uploadFile}
                    customRequest={() => null}
                    multiple={false}
                    showUploadList={false}
                    accept=".csv"
                    name="file"
                >
                    <Button icon={<DownloadOutlined />}>Upload Template</Button>
                </Upload>
            )}

            <ConfirmationSimpleModal
                visible={isOpenConfirm}
                onCancel={() => setIsOpenConfirm(false)}
                headerText="Are you sure you want to continue?"
                submitText="Remove"
                onConfirm={removeTemplate}
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <span>
                        The accommodations template will be removed. The agent will have access to all
                        accommodations that are active from the supplies enabled to the agent
                    </span>
                )}
            </ConfirmationSimpleModal>
        </>
    );
};

export default UploadTemplate;

import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMaruokaMethods from 'core/maruoka-methods';
import { date, Loader } from 'legacy';
import Table from 'components/table';
import { Button, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Statuses = {
    0: 'In Progress',
    1: 'Done',
    2: 'Partially Failed',
    3: 'Failed',
};

export const getFileName = ({ accommodationHtId, accommodationName, checkInDate, checkOutDate, created }) => {
    const name = accommodationName || accommodationHtId;

    return `${name}_from_${date.format.dots(checkInDate)}_to_${date.format.dots(
        checkOutDate
    )}_loaded_${date.format.dots(created || new Date())}.csv`;
};

const columns = (download) => [
    {
        header: 'Name',
        cell: ({ fileName }) => fileName,
    },
    {
        header: 'Status',
        cell: ({ status }) => Statuses[status],
    },
    {
        header: '',
        cell: ({ searchId, status }) =>
            status === 1 || status === 2 ? (
                <Button icon={<DownloadOutlined />} onClick={() => download(searchId)}>
                    Download
                </Button>
            ) : null,
    },
];

const RateComparisonHistory = ({ download, searchResult }) => {
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = () => {
        API.get({
            maruoka: apiMaruokaMethods.searchHistory,
            success: (data) => {
                setList(
                    data.map((item) => {
                        return {
                            ...item,
                            fileName: getFileName(item),
                        };
                    })
                );
            },
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getData();
    }, [searchResult]);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ marginBottom: 20 }}>
                Search History
            </Title>

            <Table list={list} columns={columns(download)} />
        </>
    );
};

export default RateComparisonHistory;

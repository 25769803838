import React from 'react';
import { Form, Menu } from 'antd';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import BookingsItinerary from '../itinerary/bookings-itinerary';
import AgencyOrAgentAllBookingsWithAutoCancellation from './agency-or-agent-all-bookings-with-autocancellation';

const agentBookingsMenuItems = [
    {
        label: <Link to="./all">All</Link>,
        key: 'all',
    },
    {
        label: <Link to="./itinerary">Itinerary</Link>,
        key: 'itinerary',
    },
];

const AgencyOrAgentBookings = ({ isSurrogate, agencyId, agentId, agency }) => {
    const [form] = Form.useForm();

    const location = useLocation();
    const currentTab = location.pathname.split('/').pop();

    return (
        <>
            <Menu
                mode="horizontal"
                items={agentBookingsMenuItems}
                selectedKeys={currentTab}
                style={{ marginTop: -20 }}
            />
            <Routes>
                <Route
                    path="/all"
                    element={
                        <AgencyOrAgentAllBookingsWithAutoCancellation
                            isSurrogate={isSurrogate}
                            agencyId={agencyId}
                            agentId={agentId}
                            agency={agency}
                            bookingSearchForm={form}
                        />
                    }
                />
                <Route
                    path="/itinerary"
                    element={
                        <BookingsItinerary
                            isSurrogate={isSurrogate}
                            agencyId={agencyId}
                            agentId={agentId}
                            searchForm={form}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default AgencyOrAgentBookings;

import React from 'react';
import { Link } from 'react-router-dom';
import TableExpandIcon from 'components/table-expand-icon';
import AgentsActivitySwitcher from './agents-activity-switcher';

export const columns = (expandedRowKeys, onExpand) => [
    {
        header: 'ID',
        cell: ({ id }) => id,
    },
    {
        header: 'Name',
        cell: ({ name, id, agents }) => (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Link target="_blank" to={`/agency/${id}/agents`}>
                    {name}
                </Link>
                <TableExpandIcon
                    hide={!agents.length}
                    expanded={expandedRowKeys.includes(id)}
                    onExpand={() => onExpand(id)}
                />
            </div>
        ),
    },
    {
        header: 'Traffic Restrictions',
        cell: ({ qps }) => <span>{qps} QPS</span>,
    },
    {
        header: 'Supplier Settings',
        cell: ({ isSupplierEnabled }) => (isSupplierEnabled ? 'Active' : 'Inactive'),
    },
];

export const agentColumns = (agencyId) => [
    {
        title: 'Agent ID',
        render: ({ id }) => id,
    },
    {
        title: 'Agent Name',
        render: ({ name, id }) => (
            <Link target="_blank" to={`/agency/${agencyId}/agents/${id}/settings`}>
                {name}
            </Link>
        ),
    },
    {
        title: 'Agent Traffic Restriction',
        render: ({ qps }) => <span>{qps} QPS</span>,
    },
    {
        title: 'Agent Supplier Settings',
        render: ({ isSupplierEnabled, id }) => (
            <AgentsActivitySwitcher initialValue={isSupplierEnabled} agentId={id} agencyId={agencyId} />
        ),
    },
];

import React, { useMemo, useState } from 'react';
import { date } from 'legacy';
import { API } from 'htcore';
import apiOsakaMethods from 'core/osaka-methods';
import { Alert, Button, Col, DatePicker, Form, Input, Row, Space } from 'antd';
import LocalitySelector from 'components/selectors/locality-selector';
import { getUTCDate } from 'components/selectors/date-ranges-selector';
import CountrySelector from 'components/selectors/country-selector';
import SupplierSelector from 'components/selectors/supplier-selector';

const { RangePicker } = DatePicker;

const RateComparisonForm = ({
    form,
    loading,
    searchResult,
    errorMessage,
    downloadResults,
    startNewSearch,
}) => {
    const formValues = Form.useWatch([], form);

    const [firstDate, setFirstDate] = useState(null);

    const onCalendarChange = (value) => {
        setFirstDate(value?.[0] || value?.[1]);
    };

    const getNumberOfDays = (dates) => {
        if (dates) {
            const [dateFrom, dateTo] = dates;
            return dateTo.diff(dateFrom, 'days');
        }
        return 0;
    };

    const disabledDate = (current, selectedDates) => {
        const [checkIn, checkOut] = selectedDates || [];
        const dateNow = getUTCDate().set({ hour: 23, minute: 59 });

        if (current.isBefore(dateNow)) return true;

        if ((checkIn && current.isSame(checkIn)) || (checkOut && current.isSame(checkOut))) {
            return true;
        }

        return !!(firstDate && current.isSame(firstDate));
    };

    const validateRangeDates = (_, value) => {
        if (!value) return Promise.resolve();

        if (getNumberOfDays(value) > 7)
            return Promise.reject(new Error('You cannot choose more than 7 days'));

        return Promise.resolve();
    };

    const onChangeIdField = (event) => {
        const value = event.target.value;

        if (value) {
            API.get({
                osaka: apiOsakaMethods.predictionsRestore(value),
                ignoreErrors: true,
                success: ({ htId, predictionText }) => {
                    form.setFieldValue('accommodationHtId', { label: predictionText, value: htId });
                    form.validateFields(['accommodationHtId']);
                },
                error: () => form.setFieldValue('accommodationHtId', null),
            });
        }
    };

    const isSearchButtonActive = useMemo(() => {
        const { accommodationHtId, rangeDates, nationality } = formValues || {};
        const [fromDate, toDate] = rangeDates || [];

        return accommodationHtId && fromDate && toDate && nationality;
    }, [formValues]);

    return (
        <>
            <Row size="large" gutter={24}>
                <Col span={6}>
                    <Form.Item
                        name="accommodationHtId"
                        label="Accommodation Name"
                        rules={[{ required: true, message: 'Please select' }]}
                    >
                        <LocalitySelector
                            filter={['Accommodation']}
                            placeholder="Enter Name"
                            onChange={(id, value) => {
                                form.setFieldValue('accommodationHtId', value);
                                form.setFieldValue('accommodationId', id);
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Form.Item label="Accommodation ID" name="accommodationId">
                        <Input placeholder="ID" onChange={onChangeIdField} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => (
                            <Form.Item
                                name="rangeDates"
                                label="Dates"
                                rules={[{ required: true }, { validator: validateRangeDates }]}
                            >
                                <RangePicker
                                    format={date.format.c}
                                    placeholder={['Check In', 'Check Out']}
                                    disabledDate={(current) =>
                                        disabledDate(current, getFieldValue('rangeDates'))
                                    }
                                    onCalendarChange={onCalendarChange}
                                    onOpenChange={() => setFirstDate(null)}
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>

                <Col span={3}>
                    <Form.Item name="nationality" label="Nationality" rules={[{ required: true }]}>
                        <CountrySelector
                            idName="code"
                            onChange={(_, item) => form.setFieldValue('nationality', item)}
                            placeholder="Nationality"
                        />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name="supplierCodes" label="Suppliers">
                        <SupplierSelector onlyEnabled withTestOnly={false} multiple placeholder="All" />
                    </Form.Item>
                </Col>
            </Row>

            {errorMessage && (
                <Alert
                    description={errorMessage}
                    type="warning"
                    showIcon
                    style={{
                        margin: '0 20px 20px 0',
                        padding: 18,
                        borderColor: '#d9d9d9',
                        backgroundColor: '#d9d9d915',
                    }}
                />
            )}

            <Space>
                {searchResult && (
                    <Button
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => downloadResults(searchResult)}
                    >
                        Load Results
                    </Button>
                )}

                {errorMessage || searchResult ? (
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                        onClick={startNewSearch}
                    >
                        Start New Search
                    </Button>
                ) : (
                    <Button
                        loading={loading}
                        disabled={!isSearchButtonActive}
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                    >
                        Search
                    </Button>
                )}
            </Space>
        </>
    );
};

export default RateComparisonForm;

import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { date, Loader } from 'legacy';
import { Typography } from 'antd';
import Table from 'components/table';

const { Title } = Typography;

const SettingTypesNames = {
    RoomGrouping: 'Room Mapping',
};

const RoomMappingHistory = () => {
    const [list, setList] = useState(null);

    const getData = () => {
        API.get({
            url: apiMethods.roomMappingHistory,
            success: setList,
        });
    };

    useEffect(() => {
        getData();
    }, []);

    if (!list) {
        return <Loader />;
    }

    return (
        <>
            <Title level={4} style={{ marginBottom: 40 }}>
                Change History
            </Title>
            <Table
                list={list}
                columns={[
                    {
                        header: 'Date',
                        cell: ({ created }) => date.format.e(created),
                    },
                    {
                        header: 'Setting',
                        cell: ({ settingsType }) => {
                            const name = SettingTypesNames?.[settingsType];

                            return name || settingsType.replace(/([A-Z])/g, ' $1');
                        },
                    },
                    {
                        header: 'Action',
                        cell: 'action',
                    },
                    {
                        header: 'Admin Name',
                        cell: ({ createdBy }) => createdBy || '—',
                    },
                    {
                        header: 'Reason',
                        cell: 'reason',
                    },
                ]}
                textEmptyResult="No history"
            />
        </>
    );
};

export default RoomMappingHistory;

import React from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { API } from 'htcore';
import sunpuMethods from 'core/sunpu-methods';
import { Loader } from 'legacy';
import { Button, Checkbox, Form, Space, Typography, Row, InputNumber, Switch } from 'antd';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const { Title } = Typography;

const remapForServer = (lifetimeSettings) =>
    lifetimeSettings.map((item) => {
        const days = Math.trunc((item.hours || 0) / 24);
        const hours = (item.hours || 0) % 24;
        let lifeTime = [hours, item.minutes || 0, 0].map((v) => String(v).padStart(2, '0')).join(':');
        if (days) {
            lifeTime = [days, lifeTime].join('.');
        }

        return {
            fromDays: item.fromDays || 0,
            lifeTime,
        };
    });

const remapForClient = (lifetimeSettings) =>
    lifetimeSettings?.length
        ? lifetimeSettings.map((item) => {
              const days = item.lifeTime.split('.');
              const lifeTime = days.pop().split(':');

              let hours = parseInt(lifeTime[0]);
              const minutes = parseInt(lifeTime[1]);
              if (days.length) {
                  hours += parseInt(days[0]) * 24;
              }

              return {
                  fromDays: item.fromDays,
                  hours,
                  minutes,
              };
          })
        : [{}];

const SupplierCachePage = ({ isReadOnly, supplier, code, loadSupplier }) => {
    const [supplierCacheForm] = Form.useForm();

    const submit = (values) => {
        if (values.lifetimeSettings) {
            values.lifetimeSettings = remapForServer(values.lifetimeSettings);
        }

        API.post({
            sunpu: sunpuMethods.supplierDataCacheSettings(code),
            body: {
                ...supplier.cacheSettings,
                ...values,
            },
            success: () => {
                loadSupplier();
                notifications('Cache Settings Saved!', 'success');
            },
        });
    };

    if (code && !supplier) return <Loader />;

    return (
        <>
            <Form
                form={supplierCacheForm}
                initialValues={{
                    ...supplier.cacheSettings,
                    lifetimeSettings: remapForClient(supplier.cacheSettings.lifetimeSettings),
                }}
                layout="vertical"
                onFinish={submit}
                style={{ maxWidth: 1000 }}
                className={isReadOnly && 'disable-full-form'}
                disabled={isReadOnly}
            >
                <Title level={4} style={{ marginBottom: 20 }}>
                    Cache
                    <Form.Item
                        name="isEnabled"
                        valuePropName="checked"
                        style={{ display: 'inline-block', marginTop: -8 }}
                    >
                        <Switch style={{ marginLeft: 15, marginTop: -4 }} />
                    </Form.Item>
                </Title>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.isEnabled !== currentValues.isEnabled
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('isEnabled') && (
                            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                <div>Search parameters to be considered in cache:</div>
                                <Space size="large">
                                    <Form.Item
                                        noStyle
                                        name={['keySettings', 'shouldAddAgencyCountryCode']}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Residency</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        name={['keySettings', 'shouldAddNationality']}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Nationality</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        name={['keySettings', 'shouldAddResidency']}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Agency Country Code</Checkbox>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        name={['keySettings', 'shouldAddSearchFilter']}
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Search Filter</Checkbox>
                                    </Form.Item>
                                </Space>
                                <Title level={5} style={{ marginTop: 20 }}>
                                    Default Settings
                                </Title>

                                <Form.List name="lifetimeSettings">
                                    {(fields, { add, remove }) => {
                                        return fields.map((field, index) => {
                                            const isAddingPossible = index === 0 && fields.length < 10;
                                            return (
                                                <Row key={field.key}>
                                                    <Space direction="vertical" size="middle">
                                                        Booking Window
                                                        <Form.Item
                                                            label="From"
                                                            name={[field.name, 'fromDays']}
                                                            style={{ marginRight: 40 }}
                                                        >
                                                            <InputNumber
                                                                type="number"
                                                                placeholder="0"
                                                                style={{ width: 200 }}
                                                                controls={false}
                                                                addonAfter="Days"
                                                                min={0}
                                                                max={365}
                                                            />
                                                        </Form.Item>
                                                    </Space>
                                                    <Space direction="vertical" size="small">
                                                        Cache Lifetime
                                                        <Space direction="horizontal">
                                                            <Form.Item
                                                                label="Hours"
                                                                name={[field.name, 'hours']}
                                                            >
                                                                <InputNumber
                                                                    type="number"
                                                                    placeholder="0"
                                                                    addonAfter="Hours"
                                                                    min={0}
                                                                    max={48}
                                                                    style={{ width: 200, marginTop: -3 }}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Minutes"
                                                                name={[field.name, 'minutes']}
                                                            >
                                                                <InputNumber
                                                                    type="number"
                                                                    placeholder="0"
                                                                    addonAfter="Minutes"
                                                                    min={0}
                                                                    max={59}
                                                                    style={{ width: 200, marginTop: -3 }}
                                                                />
                                                            </Form.Item>
                                                        </Space>
                                                    </Space>
                                                    <div style={{ padding: '43px 0 0 50px' }}>
                                                        {isAddingPossible ? (
                                                            <Button onClick={() => add({})}>
                                                                <PlusOutlined />
                                                            </Button>
                                                        ) : (
                                                            <Button onClick={() => remove(field.name)}>
                                                                <MinusOutlined />
                                                            </Button>
                                                        )}
                                                    </div>
                                                </Row>
                                            );
                                        });
                                    }}
                                </Form.List>
                            </Space>
                        )
                    }
                </Form.Item>

                {$auth.permitted('SupplierManagement') && (
                    <Button htmlType="submit" type="primary" style={{ marginTop: 30 }}>
                        Save Changes
                    </Button>
                )}
            </Form>
        </>
    );
};

export default SupplierCachePage;

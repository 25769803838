import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Row, Col, Space, DatePicker, Select, Input } from 'antd';
import { date } from 'legacy';
import moment from 'moment/moment';
import AccountManagerSelector from 'components/selectors/account-manager-selector';

const { RangePicker } = DatePicker;
const { Option } = Select;

const RegistrationRequestsSearchForm = ({ registrationRequestSearchForm, onSubmit }) => {
    return (
        <Form
            form={registrationRequestSearchForm}
            onFinish={onSubmit}
            layout="vertical"
            initialValues={{}}
            style={{ marginBottom: 30 }}
        >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col lg={12} xl={6}>
                    <Form.Item name="status" label="Status">
                        <Select placeholder="Any Status" allowClear>
                            <Option value="Awaiting">Awaiting</Option>
                            <Option value="Created">Agency Created</Option>
                            <Option value="InvitationSent">Invitation Sent</Option>
                            <Option value="Done">Done</Option>
                            <Option value="Rejected">Rejected</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="nameQuery" label="Name Query">
                        <Input placeholder="Part of Company Name" allowClear />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="rangeDates" label="Creation Date Period">
                        <RangePicker
                            format={date.format.c}
                            placeholder={['From', 'Till']}
                            disabledDate={(current) => current && current > moment()}
                        />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="accountManagerId" label="Account manager">
                        <AccountManagerSelector isWithEmptyOption placeholder="Any Account Manager" />
                    </Form.Item>
                </Col>
                <Col lg={12} xl={6}>
                    <Form.Item name="sortField" label="Sort By">
                        <Select placeholder="Not Sorted" allowClear>
                            <Option value="CompanyName">Company Name</Option>
                            <Option value="FirstName">Agent Name</Option>
                            <Option value="Created">Date of the Request</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Row style={{ width: '100%', marginLeft: '16px' }}>
                    <Col span={16}>
                        <Space size="large">
                            <Button type="primary" htmlType="submit">
                                Search Registration Requests
                            </Button>
                            <Button
                                htmlType="button"
                                onClick={() => registrationRequestSearchForm.resetFields()}
                            >
                                Clear Filters
                            </Button>
                        </Space>
                    </Col>
                    <Col span={8} style={{ textAlign: 'right' }}>
                        <Link to="./create">
                            <Button type="primary" htmlType="submit">
                                Register New Active Agency
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Row>
        </Form>
    );
};

export default RegistrationRequestsSearchForm;

import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import RestrictionsMarkets from './restrictions-markets';
import RestrictionsCountries from './restrictions-countries';
import RestrictionsHistory from './restrictions-history';

const menuItems = [
    {
        label: <Link to="./markets">Markets</Link>,
        key: 'markets',
    },
    {
        label: <Link to="./countries">Countries/Localities</Link>,
        key: 'countries',
    },
    {
        label: <Link to="./history">Change History</Link>,
        key: 'history',
    },
];

const RestrictionsPage = () => {
    const location = useLocation();

    return (
        <>
            <Menu mode="horizontal" items={menuItems} selectedKeys={location.pathname.split('/')[3] || ''} />
            <Routes>
                <Route path="/markets/:code?" element={<RestrictionsMarkets />} />
                <Route path="/countries/:code?" element={<RestrictionsCountries />} />
                <Route path="/history" element={<RestrictionsHistory />} />
            </Routes>
        </>
    );
};

export default RestrictionsPage;

import React, { useState } from 'react';
import { API, useTitle } from 'htcore';
import { useNavigate } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Space } from 'antd';
import { notifications } from 'components/notification';
import LocalitySelector from 'components/selectors/locality-selector';

const AgencyCreationPage = () => {
    useTitle('Agencies');

    const navigate = useNavigate();
    const [agencyCreateForm] = Form.useForm();
    const [selectedLocality, setSelectedLocality] = useState('');

    const submit = (values) => {
        API.post({
            url: apiMethods.agencyCreate(),
            body: values,
            success: (newAgency) => {
                notifications('Agency Created!', 'success');
                navigate(`/agency/${newAgency.id}/details`);
            },
        });
    };

    return (
        <Form form={agencyCreateForm} layout="vertical" onFinish={submit} style={{ maxWidth: 600 }}>
            <Form.Item name="name" label="Name" rules={[{ required: true }]}>
                <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="localityHtId" label="Locality" rules={[{ required: true }]}>
                <LocalitySelector setSelectedLabel={setSelectedLocality} />
            </Form.Item>
            <Form.Item name="address" label="Address" rules={[{ required: true }]}>
                <Input placeholder="Address" />
            </Form.Item>
            <Form.Item name="legalAddress" label="Legal Address" rules={[{ required: true }]}>
                <Input placeholder="Legal Address" />
            </Form.Item>
            <Form.Item name="postalCode" label="Postal Code">
                <Input placeholder="Postal Code" />
            </Form.Item>
            <Space>
                <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
                    <InputNumber
                        type="number"
                        prefix="+"
                        placeholder="Phone"
                        style={{ width: '100% ' }}
                        controls={false}
                    />
                </Form.Item>
                <Form.Item name="fax" label="Fax">
                    <InputNumber
                        type="number"
                        prefix="+"
                        placeholder="Fax"
                        style={{ width: '100% ' }}
                        controls={false}
                    />
                </Form.Item>
            </Space>
            <Form.Item name="vatNumber" label="Vat Number">
                <Input placeholder="Vat Number" />
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.localityHtId !== currentValues.localityHtId ||
                    prevValues.nonTRN !== currentValues.nonTRN
                }
            >
                {({ getFieldValue, setFieldValue }) =>
                    selectedLocality?.includes('United Arab Emirates') && (
                        <>
                            <div style={{ marginBottom: 10 }}>
                                <Form.Item noStyle name="nonTRN" valuePropName="checked">
                                    <Checkbox
                                        onChange={(event) => {
                                            event.target.checked &&
                                                setFieldValue('taxRegistrationNumber', '');
                                        }}
                                    >
                                        Non VAT Registered Agency
                                    </Checkbox>
                                </Form.Item>
                            </div>
                            {!getFieldValue('nonTRN') && (
                                <Form.Item
                                    name="taxRegistrationNumber"
                                    label="TRN"
                                    rules={[
                                        { required: true },
                                        { min: 15, max: 15, message: 'TRN is 15 digits long' },
                                    ]}
                                >
                                    <Input maxLength={15} placeholder="Tax Registration Number" />
                                </Form.Item>
                            )}
                        </>
                    )
                }
            </Form.Item>

            <Form.Item
                name="notificationEmail"
                label="Email For Notifications"
                rules={[{ type: 'email', message: 'Please enter a valid email address.' }]}
            >
                <Input placeholder="Email For Agency Notifications" />
            </Form.Item>
            <Form.Item
                name="billingEmail"
                label="Billing Email"
                rules={[
                    { required: true },
                    { type: 'email', message: 'Please enter a valid email address.' },
                ]}
            >
                <Input placeholder="Billing Email" />
            </Form.Item>
            <Row align="space-between" gutter={8}>
                <Col span={12}>
                    <Form.Item
                        name={['contactInfo', 'name']}
                        label="Contact Name"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Contact Name" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name={['contactInfo', 'email']}
                        label="Contact Email"
                        rules={[
                            { required: true },
                            { type: 'email', message: 'Please enter a valid email address.' },
                        ]}
                    >
                        <Input placeholder="Contact Email" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Button htmlType="submit">Create Agency</Button>
            </Form.Item>
        </Form>
    );
};

export default AgencyCreationPage;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import $auth from 'stores/auth';
import moment from 'moment';
import { Loader, date } from 'legacy';
import { Button, Form, Input, PageHeader, Typography, Row, Col, Space, Switch } from 'antd';
import ConfirmationPopup from 'components/confirms/confirmation-popup';
import { notifications } from 'components/notification';
import DateRangesSelector from 'components/selectors/date-ranges-selector';
import TypeSelector from 'components/selectors/type-selector';
import FieldWithExclude from 'components/selectors/field-with-exclude';
import {
    getInitialValues,
    getSavedValues,
    mapFieldsToPayload,
    getValidation,
} from 'legacy/excluded-form-utils';

import MarkupLocalitySelector from '../../markups/markup-management/selectors/markup-locality-selector';
import MarkupAccommodationSelector from '../../markups/markup-management/selectors/markup-accommodation-selector';

const { Title } = Typography;
const { TextArea } = Input;

const mapToViewDates = (dates) => {
    if (!dates.length) {
        return [[]];
    }

    return dates.map((item) => [moment(item.fromDate), moment(item.toDate)]);
};

const mapToPayloadDates = (dates) =>
    dates
        .filter((item) => item && item.length)
        .map(([fromDate, toDate]) => ({
            fromDate: date.format.apiDateOnly(fromDate),
            toDate: date.format.apiDateOnly(toDate),
        }));

// The fields listed in the arrays must be dependent on each other according to the "HP-5777" task specification
// Fields in arrays are arranged in a certain way: all the following fields depend on an array element.
// For example, when an element with index 2 changes, elements with indexes 3-[end of array] should be cleaned up
const AGENCY_FIELDS = [
    'agencyMarketIds',
    'agencyCountryCodes',
    'agencyLocalityHtIds',
    'agencyIds',
    'agentIds',
];

const ACCOMMODATION_FIELDS = [
    'accommodationSupplierTypes',
    'accommodationSupplierCodes',
    'accommodationBrandIds',
    'accommodationChainIds',
    'accommodationMarketIds',
    'accommodationCountryCodes',
    'accommodationLocalityHtIds',
    'accommodationHtIds',
];

export const FIELDS_WITH_EXCLUDE = [...AGENCY_FIELDS, ...ACCOMMODATION_FIELDS];

const initialValues = {
    ...getInitialValues(FIELDS_WITH_EXCLUDE),
    isActive: true,
    description: '',
    agentIds: [],
    agencyIds: [],
    agencyCountryCodes: [],
    agencyLocalityHtIds: [],
    agencyMarketIds: [],
    accommodationHtIds: [],
    accommodationSupplierTypes: [],
    accommodationSupplierCodes: [],
    accommodationLocalityHtIds: [],
    accommodationCountryCodes: [],
    accommodationBrandIds: [],
    accommodationChainIds: [],
    accommodationMarketIds: [],
    travelDates: [[]],
    bookingDates: [[]],
};

const optionsGenerator = (list = []) =>
    list.map(({ code, id, name: label }) => ({ value: code || id, label }));

const getMarketOptions = (list = []) => list.filter(({ name }) => !name.includes('Unknown'));

const formatDataToView = (data) => ({
    ...data,
    travelDates: mapToViewDates(data.travelDates),
    bookingDates: mapToViewDates(data.bookingDates),
    ...getSavedValues(data, FIELDS_WITH_EXCLUDE),
});

const getIsEqualData = (newData, savedData) => {
    const newDataCopy = { ...newData };
    const savedDataCopy = { ...savedData, excludedAgentIds: savedData.excludedAgentIds || [] };

    delete newDataCopy.isActive;
    delete savedDataCopy.isActive;

    return JSON.stringify(newDataCopy) === JSON.stringify(savedDataCopy);
};

const Restriction = ({ updateList }) => {
    const [restriction, setRestriction] = useState(null);
    const [options, setOptions] = useState({});

    const [form] = Form.useForm();
    const formValues = Form.useWatch([], form);

    const navigate = useNavigate();
    const { id } = useParams();
    const creation = id === 'create';

    const getOptions = (data) =>
        API.post({
            url: apiMethods.supplierOptions,
            body: createPayload(data || formValues),
            success: setOptions,
        });

    useEffect(() => {
        if (creation) {
            getOptions(initialValues);
            return;
        }
        API.get({
            url: apiMethods.restriction(id),
            success: (item) => {
                setRestriction(item);
                getOptions(formatDataToView(item));
            },
        });
    }, []);

    const changeActivation = (isActive) => {
        API.post({
            url: isActive ? apiMethods.activateRestriction(id) : apiMethods.deactivateRestriction(id),
            success: () =>
                notifications(isActive ? 'Restriction Activated!' : 'Restriction Deactivated!', 'success'),
        });
    };

    const createPayload = (body) => {
        const values = { ...body };

        values.travelDates = mapToPayloadDates(values.travelDates);
        values.bookingDates = mapToPayloadDates(values.bookingDates);

        return mapFieldsToPayload(FIELDS_WITH_EXCLUDE, values);
    };

    const submit = async (values) => {
        const body = createPayload(form.getFieldsValue(true));

        if (!creation && values.isActive !== restriction.isActive) changeActivation(values.isActive);

        if (creation) {
            await API.post({
                url: apiMethods.supplierRestrictions,
                body,
                success: () => {
                    notifications('Restriction Created!', 'success');
                    navigate('../');
                },
            });
        } else {
            if (getIsEqualData(body, restriction)) {
                navigate('../');
                return;
            }
            await API.put({
                url: apiMethods.updateRestriction(id),
                body,
                success: () => {
                    notifications('Restriction Saved', 'success');
                    navigate('../');
                },
            });
        }

        updateList?.();
    };

    const remove = () => {
        API.delete({
            url: apiMethods.updateRestriction(id),
            success: () => {
                notifications('Restriction Deleted!', 'success');
                navigate('../');
            },
        });
    };

    const clearFields = (changedField) => {
        const allFields = [AGENCY_FIELDS, ACCOMMODATION_FIELDS];
        const fieldsWithDependency = allFields.find((item) => item.includes(changedField));

        if (!fieldsWithDependency) {
            // case when the "Type" fields are changed.
            return clearFields(changedField.slice(0, -4));
        }
        const index = fieldsWithDependency.indexOf(changedField);

        const arrayForCleaning = fieldsWithDependency.slice(index + 1);

        const fields = arrayForCleaning.reduce((acc, item) => {
            acc[item] = [];
            return acc;
        }, {});

        form.setFieldsValue(fields);
        return fields;
    };

    const onValuesChange = (values, allValues) => {
        const fieldName = Object.keys(values || {})?.[0];

        if (FIELDS_WITH_EXCLUDE.find((item) => fieldName.includes(item))) {
            const clearedFields = clearFields(fieldName);

            getOptions({ ...allValues, ...clearedFields });
        }
    };

    const getIsPastDate = (date) => moment(date).isBefore(moment().add(-1, 'day'));

    const disabledSelectedDatesInPast = (fieldName) => (key) => {
        const period = form.getFieldValue(fieldName);

        return [getIsPastDate(period?.[key]?.[0]), getIsPastDate(period?.[key]?.[1])];
    };

    if (!restriction && !creation) {
        return <Loader />;
    }

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={
                    restriction
                        ? `Edit Restriction #${id}: ${restriction.description}`
                        : id === 'create'
                        ? 'Create New Restriction'
                        : ''
                }
            />
            <Form
                form={form}
                initialValues={restriction ? formatDataToView(restriction) : initialValues}
                layout="vertical"
                onFinish={submit}
                onValuesChange={onValuesChange}
                style={{ pointerEvents: $auth.permitted('SupplierManagement') ? 'auto' : 'none' }}
            >
                <Space align="baseline" size="large" style={{ marginBottom: 32 }}>
                    <Title level={4}>Activity</Title>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue, setFieldValue }) => {
                            const checked = getFieldValue('isActive');
                            return (
                                <Form.Item name="isActive" noStyle>
                                    <Switch
                                        checked={checked}
                                        onChange={() => setFieldValue('isActive', !checked)}
                                    />
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Space>
                <div>
                    <Title level={4}>Description</Title>

                    <Form.Item
                        name="description"
                        label="Description"
                        rules={[{ required: true, message: 'Please enter Description' }]}
                    >
                        <TextArea
                            placeholder="Enter Description"
                            showCount
                            maxLength={200}
                            style={{ height: 80 }}
                        />
                    </Form.Item>
                </div>

                <Row size="large" style={{ width: '100%', minWidth: 600 }}>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Travel Dates
                        </Title>

                        <DateRangesSelector
                            maxDatesNumber={3}
                            fieldName="travelDates"
                            getFieldValue={() => form.getFieldValue('travelDates')}
                            disabledFields={disabledSelectedDatesInPast('travelDates')}
                        />
                    </Col>

                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Booking Dates
                        </Title>
                        <DateRangesSelector
                            fieldName="bookingDates"
                            maxDatesNumber={3}
                            getFieldValue={() => form.getFieldValue('bookingDates')}
                            disabledFields={disabledSelectedDatesInPast('bookingDates')}
                        />
                    </Col>
                </Row>

                <Row size="large" style={{ width: '100%', minWidth: 600 }}>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Supplier
                        </Title>

                        <FieldWithExclude
                            name="accommodationSupplierTypes"
                            label="Supplier Type"
                            options={(options.accommodationSupplierTypes || []).map((item) => ({
                                label: item,
                                value: item,
                            }))}
                        />

                        <FieldWithExclude
                            name="accommodationSupplierCodes"
                            label="Supplier Name"
                            options={optionsGenerator(options.accommodationSupplierCodes)}
                        />

                        <FieldWithExclude
                            name="accommodationBrandIds"
                            label="Chains/Brands"
                            options={optionsGenerator(options.accommodationBrands)}
                        />

                        <FieldWithExclude
                            name="accommodationMarketIds"
                            label="Accommodation Market"
                            options={optionsGenerator(getMarketOptions(options.accommodationMarketIds))}
                        />

                        <FieldWithExclude
                            name="accommodationCountryCodes"
                            label="Accommodation Country"
                            options={optionsGenerator(options.accommodationCountryCodes)}
                        />

                        <Row>
                            <TypeSelector name="accommodationLocalityHtIds" />
                            <Form.Item
                                name="accommodationLocalityHtIds"
                                label="Accommodation Locality"
                                style={{ flex: 1 }}
                                rules={[getValidation('accommodationLocalityHtIds')]}
                            >
                                <MarkupLocalitySelector
                                    isAccommodation
                                    placeholder="ALL"
                                    multiple
                                    options={options}
                                    formValues={formValues}
                                />
                            </Form.Item>
                        </Row>

                        <Row>
                            <TypeSelector name="accommodationHtIds" />
                            <Form.Item
                                name="accommodationHtIds"
                                label="Accommodation"
                                style={{ flex: 1 }}
                                rules={[getValidation('accommodationHtIds')]}
                            >
                                <MarkupAccommodationSelector
                                    placeholder="ALL"
                                    multiple
                                    formValues={formValues}
                                    options={options}
                                />
                            </Form.Item>
                        </Row>
                    </Col>

                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Title level={4} style={{ marginTop: 30 }}>
                            Agency
                        </Title>

                        <FieldWithExclude
                            name="agencyMarketIds"
                            label="Agency Market"
                            options={optionsGenerator(getMarketOptions(options.agencyMarketIds))}
                        />

                        <FieldWithExclude
                            name="agencyCountryCodes"
                            label="Agency Country"
                            options={optionsGenerator(options.agencyCountryCodes)}
                        />

                        <Row>
                            <TypeSelector name="agencyLocalityHtIds" />
                            <Form.Item
                                style={{ flex: 1 }}
                                name="agencyLocalityHtIds"
                                label="Agency Locality"
                                rules={[getValidation('agencyLocalityHtIds')]}
                            >
                                <MarkupLocalitySelector
                                    placeholder="ALL"
                                    multiple
                                    options={options}
                                    formValues={formValues}
                                />
                            </Form.Item>
                        </Row>

                        <FieldWithExclude
                            name="agencyIds"
                            label="Agency Name"
                            options={(options.agencyIds || []).map((item) => ({
                                value: item.id,
                                label: `${item.name} (${item.id})`,
                            }))}
                        />

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue('agencyIds').length === 1 && (
                                    <FieldWithExclude
                                        name="agentIds"
                                        label="Agents"
                                        options={optionsGenerator(options.agentIds)}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>

                <div>
                    <Title level={4}>Remarks</Title>

                    <Form.Item name="remarks" label="Remarks">
                        <TextArea
                            placeholder="Enter Remarks"
                            showCount
                            maxLength={500}
                            style={{ height: 80 }}
                        />
                    </Form.Item>
                </div>

                {$auth.permitted('SupplierManagement') && (
                    <Space align="end">
                        <Button type="primary" htmlType="submit">
                            {id === 'create' ? 'Create Restriction' : 'Save Changes'}
                        </Button>

                        {id !== 'create' && Boolean(restriction) && (
                            <ConfirmationPopup
                                title={
                                    <div>The restriction will be deleted. This action cannot be undone.</div>
                                }
                                okText="Confirm"
                                cancelText="Cancel"
                                onConfirm={remove}
                            >
                                <Button>Delete</Button>
                            </ConfirmationPopup>
                        )}
                    </Space>
                )}
            </Form>
        </>
    );
};

export default Restriction;

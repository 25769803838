import React, { useState } from 'react';
import { remapStatus } from 'legacy';
import apiMethods from 'core/methods';
import ConfirmationModal from 'components/confirms/confirmation-modal';
import CurrencySelector from 'components/selectors/currency-selector';
import { Button, Row, Typography, Space, Form, InputNumber, message, Select } from 'antd';
import { API } from 'htcore';

const { Title } = Typography;
const { Option } = Select;

const AgencyVerification = ({
    isSurrogate,
    agency,
    setAgency,
    agencyAccounts,
    loadAgencyAccounts,
    availableCurrencies,
}) => {
    const [confirmVerificationModalVisible, setConfirmVerificationModalVisible] = useState(false);
    const [contractKindModalVisible, setContractKindModalVisible] = useState(false);
    const [verifyReadOnlyVisible, setVerifyReadOnlyVisible] = useState(false);
    const [declineVerificationVisible, setDeclineVerificationVisible] = useState(false);
    const [contractKind, setContractKind] = useState();

    const setVerificationState = (newState, contractKind) => {
        setAgency({
            ...agency,
            verificationState: newState,
            ...(contractKind ? { contractKind } : {}),
        });
        loadAgencyAccounts();
    };

    const verify = (contractKind) => {
        setConfirmVerificationModalVisible(true);
        setContractKind(contractKind);
    };

    const submitConfirm = (form) => {
        setConfirmVerificationModalVisible(false);
        return API.post({
            url: apiMethods.verifyFullAccess(agency.id),
            body: {
                reason: form.reason,
                ...(contractKind === 'VirtualAccountOrCreditCardPayments' ||
                contractKind === 'OfflineOrCreditCardPayments'
                    ? {
                          availableCurrenciesWithLimits: form.availableCurrencies.reduce(
                              (cur, item) => ({
                                  ...cur,
                                  [item]:
                                      contractKind === 'VirtualAccountOrCreditCardPayments'
                                          ? form.creditLimit[item]
                                          : null,
                              }),
                              {}
                          ),
                      }
                    : {}),
                ...(contractKind ? { contractKind } : {}),
            },
            success: () => {
                message.success('Verified Full Access: ' + remapStatus(contractKind) + '!');
                setVerificationState('FullAccess', contractKind);
            },
        });
    };

    const submitNewContractKind = ({ reason, contractKind: contract, creditLimit, availableCurrencies }) => {
        setContractKindModalVisible(false);

        const contractKindData = contract || contractKind || 'VirtualAccountOrCreditCardPayments';

        return API.put({
            url: apiMethods.changeContractKind(agency.id),
            body: {
                contractKind: contractKindData,
                reason,
                ...(contractKindData === 'VirtualAccountOrCreditCardPayments' ||
                contractKindData === 'OfflineOrCreditCardPayments'
                    ? {
                          availableCurrenciesWithCreditLimits: availableCurrencies.reduce(
                              (cur, item) => ({
                                  ...cur,
                                  [item]:
                                      contractKindData === 'VirtualAccountOrCreditCardPayments'
                                          ? creditLimit[item]
                                          : null,
                              }),
                              {}
                          ),
                      }
                    : {}),
            },
            success: () => {
                !isSurrogate
                    ? message.success('Changed Contract Kind: ' + remapStatus(contractKindData) + '!')
                    : message.success('Saved!');
                setVerificationState('FullAccess', contractKindData);
            },
        });
    };
    const openVerifyReadOnlyModal = () => {
        setVerifyReadOnlyVisible(true);
    };

    const verifyReadonly = ({ reason }) =>
        API.post({
            url: apiMethods.verifyReadonly(agency.id),
            body: {
                reason,
                ...(contractKind ? { contractKind } : {}),
            },
            success: () => {
                message.success('Verified Read Only!');
                setVerificationState('ReadOnly');
            },
            after: () => {
                setVerifyReadOnlyVisible(false);
            },
        });

    const openDeclineVerificationModal = () => {
        setDeclineVerificationVisible(true);
    };

    const declineVerification = ({ reason }) =>
        API.post({
            url: apiMethods.declineVerification(agency.id),
            body: {
                reason,
                ...(contractKind ? { contractKind } : {}),
            },
            success: () => {
                message.success('Verification Declined!');
                setVerificationState('DeclinedVerification');
            },
            after: () => {
                setDeclineVerificationVisible(false);
            },
        });

    return (
        <Space direction="vertical" size="middle">
            <Row>
                {agency?.verificationState === 'FullAccess' && (
                    <Space direction="vertical">
                        <Title level={5} type="primary">
                            Agency Verified!
                        </Title>
                        <div>
                            You can change {!isSurrogate ? 'Agency Contract Kind,' : ''} Credit Limits and
                            Available Currencies using this button
                        </div>

                        <Button type="secondary" onClick={() => setContractKindModalVisible(Date.now())}>
                            Change Verification Details
                        </Button>
                    </Space>
                )}
                {agency?.verificationState === 'DeclinedVerification' && (
                    <Title level={5} type="primary">
                        Verification declined.
                    </Title>
                )}
                {agency?.verificationState === 'ReadOnly' && (
                    <Title level={5} type="primary">
                        Verified as Read Only.
                    </Title>
                )}
            </Row>
            {agency?.verificationState === 'PendingVerification' && !isSurrogate && (
                <Space direction="vertical" size="small">
                    <Button type="secondary" onClick={openDeclineVerificationModal}>
                        Decline Verification
                    </Button>
                    <Button type="secondary" onClick={openVerifyReadOnlyModal}>
                        Verify Readonly
                    </Button>
                </Space>
            )}
            {agency?.verificationState === 'PendingVerification' && isSurrogate && (
                <Button
                    type="primary"
                    onClick={() => {
                        setContractKind('VirtualAccountOrCreditCardPayments');
                        setConfirmVerificationModalVisible(true);
                    }}
                >
                    Verify Virtual Account or Credit Payments
                </Button>
            )}
            {agency?.verificationState === 'ReadOnly' &&
                [
                    'OfflineOrCreditCardPayments',
                    'CreditCardPayments',
                    'VirtualAccountOrCreditCardPayments',
                ].map((contractKind) => (
                    <Space direction="vertical" key={contractKind}>
                        <Button type="secondary" onClick={() => verify(contractKind)}>
                            Verify {remapStatus(contractKind)}
                        </Button>
                    </Space>
                ))}
            <ConfirmationModal
                visible={confirmVerificationModalVisible}
                onCancel={() => setConfirmVerificationModalVisible(false)}
                headerText={
                    <div style={{ width: '95%' }}>{`You are about to verify an agency: ${remapStatus(
                        contractKind
                    )}`}</div>
                }
                submitText="Confirm"
                onSubmit={submitConfirm}
            >
                Agents in this agency will be able to book accommodations
                {(contractKind === 'VirtualAccountOrCreditCardPayments' ||
                    contractKind === 'OfflineOrCreditCardPayments') && (
                    <Form.Item
                        name="availableCurrencies"
                        label="Available Currencies"
                        rules={[{ required: true, message: 'Please Select Currencies' }]}
                        style={{ marginBottom: -15 }}
                    >
                        <CurrencySelector multiple />
                    </Form.Item>
                )}
                {contractKind === 'VirtualAccountOrCreditCardPayments' && (
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.availableCurrencies?.length !==
                            currentValues.availableCurrencies?.length
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('availableCurrencies')?.map((currency) => (
                                <Form.Item
                                    name={['creditLimit', currency]}
                                    label={currency + ' Credit Limit'}
                                    rules={[{ required: true, message: 'Please Enter Credit Limit' }]}
                                    key={currency}
                                    style={{ marginBottom: 10 }}
                                >
                                    <InputNumber
                                        placeholder="Enter a limit"
                                        style={{ width: '100%' }}
                                        addonAfter={currency}
                                        controls={false}
                                    />
                                </Form.Item>
                            ))
                        }
                    </Form.Item>
                )}
            </ConfirmationModal>
            <ConfirmationModal
                visible={verifyReadOnlyVisible}
                onCancel={() => setVerifyReadOnlyVisible(false)}
                headerText="You are about to verify an agency as Read Only"
                submitText="Confirm"
                onSubmit={verifyReadonly}
            >
                Agents in this agency will be able to book accommodations
            </ConfirmationModal>
            <ConfirmationModal
                visible={declineVerificationVisible}
                onCancel={() => setDeclineVerificationVisible(false)}
                headerText="You are about to decline an agency verification"
                submitText="Confirm"
                onSubmit={declineVerification}
            >
                Agency verification will be declined and agency will not be able to use our system
            </ConfirmationModal>
            {agencyAccounts && (
                <ConfirmationModal
                    visible={contractKindModalVisible}
                    key={contractKindModalVisible}
                    onCancel={() => setContractKindModalVisible(false)}
                    headerText="You are about to change agency contract kind"
                    submitText="Confirm"
                    onSubmit={submitNewContractKind}
                    initialValues={{
                        contractKind: agency.contractKind,
                        availableCurrencies: availableCurrencies || [],
                        creditLimit: agencyAccounts.reduce(
                            (cur, account) => ({ ...cur, [account.currency]: account.creditLimit }),
                            {}
                        ),
                    }}
                >
                    {!isSurrogate && (
                        <Form.Item
                            name="contractKind"
                            label="New Contract Kind"
                            rules={[{ required: true, message: 'Please Contract Kind' }]}
                            style={{ marginBottom: -10 }}
                        >
                            <Select placeholder="Please Select">
                                <Option value="OfflineOrCreditCardPayments">
                                    Offline Or Credit Card Payments
                                </Option>
                                <Option value="CreditCardPayments">Credit Card Payments</Option>
                                <Option value="VirtualAccountOrCreditCardPayments">
                                    Virtual Account Or Credit Card Payments
                                </Option>
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.contractKind !== currentValues.contractKind
                        }
                    >
                        {({ getFieldValue }) => (
                            <>
                                {(getFieldValue('contractKind') === 'VirtualAccountOrCreditCardPayments' ||
                                    getFieldValue('contractKind') === 'OfflineOrCreditCardPayments') && (
                                    <Form.Item
                                        name="availableCurrencies"
                                        label="Available Currencies"
                                        rules={[{ required: true, message: 'Please Select Currencies' }]}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <CurrencySelector multiple />
                                    </Form.Item>
                                )}
                                {getFieldValue('contractKind') === 'VirtualAccountOrCreditCardPayments' && (
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) =>
                                            prevValues.availableCurrencies?.length !==
                                            currentValues.availableCurrencies?.length
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('availableCurrencies')?.map((currency) => (
                                                <Form.Item
                                                    name={['creditLimit', currency]}
                                                    label={currency + ' Credit Limit'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Enter Credit Limit',
                                                        },
                                                    ]}
                                                    key={currency}
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    <InputNumber
                                                        placeholder="Enter a limit"
                                                        style={{ width: '100%' }}
                                                        addonAfter={currency}
                                                        controls={false}
                                                    />
                                                </Form.Item>
                                            ))
                                        }
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.Item>
                </ConfirmationModal>
            )}
        </Space>
    );
};

export default AgencyVerification;

import React, { useEffect, useState } from 'react';
import { RequestSelector } from 'common/request-selector';
import apiOsakaMethods from 'core/osaka-methods';
import apiShimabaraMethods from 'core/shimabara-methods';
import { API } from 'htcore';

const optionsGenerator = (list = []) =>
    list.map((value) => ({
        value: value.htId,
        label: value.name,
    }));

const defaultRequestBody = {
    countryCodes: [],
    excludedCountryCodes: [],
    supplierCodes: [],
    excludedSupplierCodes: [],
};

const AgencyLocalitySelector = ({ multiple, countryId, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(null);
    const [requestBody, setRequestBody] = useState(defaultRequestBody);

    const getMultipleDefaultOptions = () => {
        const restore = [].concat(props.value).filter((v) => v);

        if (restore.length) {
            Promise.all(
                restore.map((htId) =>
                    API.get({
                        osaka: apiOsakaMethods.predictionsRestore(htId),
                    })
                )
            ).then((results) => {
                const newOptions = restore
                    .map((item, index) =>
                        results[index]?.predictionText
                            ? {
                                  value: item,
                                  label: results[index].predictionText,
                              }
                            : null
                    )
                    .filter((v) => v);
                setDefaultOptions(newOptions);
            });
        }
    };

    useEffect(() => {
        if (multiple) {
            getMultipleDefaultOptions();
            return;
        }

        if (props.value) {
            API.get({
                osaka: apiOsakaMethods.predictionsRestore(props.value),
                success: ({ predictionText, htId }) =>
                    setDefaultOptions([{ value: htId, label: predictionText }]),
            });
        }
    }, [countryId]);

    useEffect(() => {
        setRequestBody({
            ...defaultRequestBody,
            countryCodes: [countryId],
        });
    }, [countryId]);

    return (
        <RequestSelector
            multiple={multiple}
            clearCache
            requestMethod="post"
            request={(value) => ({
                shimabara: apiShimabaraMethods.localities(value),
                body: requestBody,
            })}
            defaultOptions={defaultOptions}
            optionsGenerator={optionsGenerator}
            placeholder="Locality"
            {...props}
        />
    );
};

export default AgencyLocalitySelector;

import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import OpenedAgenciesApi from './opened-agencies-api';
import OpenedAgenciesWeb from './opened-agencies-web';

const menuItems = [
    {
        label: <Link to="./">API</Link>,
        key: '',
    },
    {
        label: <Link to="./web">WEB</Link>,
        key: 'web',
    },
];

const OpenedAgenciesPage = () => {
    const location = useLocation();

    return (
        <>
            <Menu mode="horizontal" items={menuItems} selectedKeys={location.pathname.split('/')[4] || ''} />
            <Routes>
                <Route path="/" element={<OpenedAgenciesApi />} />
                <Route path="/web" element={<OpenedAgenciesWeb />} />
            </Routes>
        </>
    );
};

export default OpenedAgenciesPage;

import React from 'react';
import { Link } from 'react-router-dom';
import { date } from 'legacy';
import { Form, Radio } from 'antd';
import { CloseCircle, TickCircle } from 'iconsax-react';

export const AVAILABILITY_VALUES = {
    INHERITED: 'Inherited',
    ON: 'On',
    OFF: 'Off',
};

const LEVEL_URLS = {
    Market: 'markets',
    Country: 'countries',
};

const RadioButton = (name, checkedValue) => {
    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldValue }) => (
                    <Form.Item name={name} noStyle>
                        <Radio
                            checked={getFieldValue(name) === checkedValue}
                            onChange={() => setFieldValue(name, checkedValue)}
                        />
                    </Form.Item>
                )}
            </Form.Item>
        </div>
    );
};

export const columns = () => [
    {
        header: 'Supplier Code',
        cell: (cell) => cell.code,
    },
    {
        header: 'Name',
        cell: (cell) => cell.name,
    },
    {
        header: 'Availability',
        cell: (cell) => (
            <div style={{ textAlign: 'center', marginTop: 6 }}>
                {cell.availability ? (
                    <TickCircle size="18" color="#59BA71" variant="Bold" />
                ) : (
                    <CloseCircle size="18" color="#EB001B" variant="Bold" />
                )}
            </div>
        ),
    },
    {
        header: 'Inherited',
        cell: ({ name }) => RadioButton(name, AVAILABILITY_VALUES.INHERITED),
    },
    {
        header: 'Always On',
        cell: ({ name }) => RadioButton(name, AVAILABILITY_VALUES.ON),
    },
    {
        header: 'Always Off',
        cell: ({ name }) => RadioButton(name, AVAILABILITY_VALUES.OFF),
    },
    {
        header: 'Note',
        cell: ({ restrictionDetails }) => {
            if (!restrictionDetails) return '-';

            const { isEnabled, level, targetCode, targetName } = restrictionDetails;

            const restrictionLevel = level === 'Locality' ? 'Country' : level;

            return (
                <span>
                    The supplier's sales are {isEnabled ? 'enabled' : 'disabled'} at the {restrictionLevel}{' '}
                    level {targetName || ''} {level === 'Locality' ? '(Check localities)' : ''}.{' '}
                    {targetCode && (restrictionLevel === 'Market' || restrictionLevel === 'Country') && (
                        <Link
                            target="_blank"
                            className="link"
                            to={`/mapper/restrictions/${LEVEL_URLS[restrictionLevel]}/${targetCode}`}
                        >
                            Change Setting
                        </Link>
                    )}
                </span>
            );
        },
    },
    {
        header: 'Last updates',
        cell: ({ modified, modifiedBy }) => (
            <span>
                {date.format.c(modified)} {date.format.shortTime(modified)}{' '}
                {modifiedBy ? `by ${modifiedBy}` : ''}
            </span>
        ),
    },
];

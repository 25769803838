import React from 'react';
import { Button, Col, Form, InputNumber, Row, Select, Space } from 'antd';
import AgencySelector from 'components/selectors/agency-selector';

const TableSearchForm = ({ searchForm, onSubmit }) => (
    <Form
        form={searchForm}
        onFinish={onSubmit}
        layout="vertical"
        style={{ marginBottom: 30 }}
        initialValues={{ supplierSetting: 'Active' }}
    >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={12} xl={6}>
                <Form.Item name="agencyId" label="Agency">
                    <AgencySelector onlyActiveAgencies placeholder="Select Agency" />
                </Form.Item>
            </Col>

            <Col lg={12} xl={6}>
                <Form.Item name="agencyId" label="Agency ID">
                    <InputNumber controls={false} placeholder="ID" style={{ width: '100%' }} />
                </Form.Item>
            </Col>

            <Col lg={12} xl={6}>
                <Form.Item name="supplierSetting" label="Supplier Settings">
                    <Select placeholder="Select" allowClear={false}>
                        <Option value="All">All</Option>
                        <Option value="Active">Active</Option>
                        <Option value="Inactive">Inactive</Option>
                    </Select>
                </Form.Item>
            </Col>

            <Col span={12} xl={6} style={{ marginTop: 6 }}>
                <Space size="large">
                    <Button type="primary" htmlType="submit" style={{ width: 170 }}>
                        Search
                    </Button>
                </Space>
            </Col>
        </Row>
    </Form>
);

export default TableSearchForm;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { notifications } from 'components/notification';

const AgentsActivitySwitcher = ({ initialValue, agentId, agencyId }) => {
    const [state, setState] = useState(initialValue);
    const [loading, setLoading] = useState(false);

    const { code } = useParams();

    const toggleActivation = (value) => {
        setLoading(true);

        API.put({
            url: value
                ? apiMethods.agentSupplierSettingsActivate(code, agentId, agencyId)
                : apiMethods.agentSupplierSettingsDeactivate(code, agentId, agencyId),
            success: () => {
                setState(!state);

                notifications(
                    value ? `Agent ${agentId} Activated!` : `Agent ${agentId} Deactivated!`,
                    'success'
                );
            },
            after: () => {
                setLoading(false);
            },
        });
    };

    return (
        <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={state}
            loading={loading}
            onChange={toggleActivation}
        />
    );
};

export default AgentsActivitySwitcher;

const PAYMENT_STATUS = {
    NotPaid: 'NotPaid',
    PartiallyPaid: 'PartiallyPaid',
    Authorized: 'Authorized',
    Invoiced: 'Invoiced',
    Refunded: 'Refunded',
    Voided: 'Voided',
};

export { PAYMENT_STATUS };

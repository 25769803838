import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { PageHeader, Space, Timeline, Typography } from 'antd';
import { API, useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { Loader, date, remapStatus } from 'legacy';
import BookingConfirmationView from './booking-confirmation-view';
import BookingActions from './booking-parts/booking-actions';
import BookingTabs from './booking-parts/booking-tabs';
import BookingEditButtons from './booking-parts/booking-edit-buttons';
import BookingServiceActions from './booking-parts/booking-service-actions';
import BookingLogs from './booking-logs';

const { Title } = Typography;

const Booking = () => {
    const { refCode } = useParams();

    useTitle(refCode);

    const serviceCode = refCode.includes('MPS') ? refCode : null;
    const isService = !!serviceCode;

    const navigate = useNavigate();
    const location = useLocation();
    const [booking, setBooking] = useState(null);
    const [history, setHistory] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [locationState, setLocationState] = useState(null);

    const temporaryIsOffline =
        refCode.includes('-OB-') ||
        booking?.bookingDetails.referenceCode.includes('-OB-') ||
        refCode.startsWith('OB-') ||
        booking?.bookingDetails.referenceCode.startsWith('OB-');

    const getHistory = (id) => {
        API.get({
            url: apiMethods.bookingStatusHistory(id),
            success: setHistory,
        });
    };

    const getService = () => {
        setLoaded(false);
        API.get({
            url: apiMethods.serviceByReferenceCode(serviceCode),
            success: (service) => {
                setBooking(service);
                getHistory(service?.bookingId);
            },
            after: () => setLoaded(true),
        });
    };

    const getBooking = () => {
        setLoaded(false);
        API.get({
            url: apiMethods.bookingsByReferenceCode(refCode),
            success: (data) => {
                setBooking(data);
                getHistory(data.bookingId);
            },
            after: () => setLoaded(true),
        });
    };

    const loadBooking = () => {
        if (isService) {
            getService();
            return;
        }
        getBooking();
    };

    useEffect(() => {
        setBooking(null);
        setLocationState(location?.state);
        loadBooking();
    }, [refCode, serviceCode]);

    if (!booking) {
        return <Loader page />;
    }

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <PageHeader
                title={
                    <>
                        {isService ? (
                            `Service Booking ${refCode}`
                        ) : temporaryIsOffline ? (
                            <span style={{ color: '#600' }}>Offline Booking {refCode}</span>
                        ) : (
                            `Booking ${refCode}`
                        )}
                    </>
                }
                onBack={() => navigate(-1, { state: locationState })}
                breadcrumb={{
                    routes: [
                        {
                            path: '/bookings',
                            breadcrumbName: 'Bookings',
                        },
                        {
                            path: `/agency/${booking.agencyId}/bookings/all`,
                            breadcrumbName: `${booking.agentInformation.agencyName} | Agency #${booking.agencyId}`,
                        },
                        {
                            path: `/agency/${booking.agencyId}/agents/${booking.agentInformation.agentId}/bookings/all`,
                            breadcrumbName: `${booking.agentInformation.agentName} | Agent #${booking.agentInformation.agentId}`,
                        },
                        {
                            breadcrumbName: refCode,
                        },
                    ],
                    itemRender: (route) =>
                        !route.path ? (
                            <span>{route.breadcrumbName}</span>
                        ) : (
                            <Link to={route.path}>{route.breadcrumbName}</Link>
                        ),
                }}
                extra={
                    <BookingEditButtons
                        isService={isService}
                        isOffline={temporaryIsOffline}
                        details={booking?.bookingDetails}
                    />
                }
            />

            {booking.itineraryBookingsCount > 1 && <BookingTabs itineraryNumber={booking.itineraryNumber} />}

            {isService ? (
                <BookingServiceActions
                    status={booking.bookingDetails?.status}
                    booking={booking}
                    loadBooking={loadBooking}
                />
            ) : (
                <BookingActions
                    isOffline={temporaryIsOffline}
                    status={booking.bookingDetails?.status}
                    booking={booking}
                    loadBooking={loadBooking}
                    paymentStatus={booking.paymentStatus}
                />
            )}

            <BookingConfirmationView
                serviceCode={serviceCode}
                isOffline={temporaryIsOffline}
                booking={booking}
                reloadBooking={loadBooking}
            />
            <Space direction="vertical" size="large" style={{ marginTop: 50 }}>
                <Title level={4}>History of Booking Changes</Title>
                <Timeline>
                    {history?.map((item) => {
                        const isConfirmationCodeChanges =
                            item.event === 'EditHotelConfirmationCode' ||
                            item.event === 'AddHotelConfirmationCode';

                        return (
                            <Timeline.Item key={item.id} color={item.source === 'System' ? 'gray' : 'green'}>
                                <b>{date.format.a(item.createdAt)}</b>{' '}
                                <span style={{ color: '#888', marginRight: 3 }}>
                                    at {date.format.time(item.createdAt)}.{' '}
                                </span>
                                {item.status && <b>{remapStatus(item.status)}</b>}
                                {isConfirmationCodeChanges && (
                                    <span>
                                        <b>
                                            {item.event === 'EditHotelConfirmationCode'
                                                ? 'Edited '
                                                : 'Added '}
                                        </b>
                                        Hotel Confirmation Code
                                        {item.event === 'EditHotelConfirmationCode' ? ' from' : ''}{' '}
                                        {item.description}
                                    </span>
                                )}
                                {['System', 'Supplier', 'Administrator'].includes(item.source) && (
                                    <>
                                        {' '}
                                        by{' '}
                                        {item.agentName ? (
                                            <Link
                                                target="_blank"
                                                className="link"
                                                to={`/agency/${item.agencyId}/agents/${item.userId}/bookings/all`}
                                            >
                                                Agent: {item.agentName}
                                            </Link>
                                        ) : item.adminName ? (
                                            <Link
                                                target="_blank"
                                                className="link"
                                                to={`/administrators/${item.userId}/details`}
                                            >
                                                Administrator: {item.adminName}
                                            </Link>
                                        ) : (
                                            item.initiator
                                        )}
                                    </>
                                )}
                                {Boolean(item.reason && !isConfirmationCodeChanges) && (
                                    <> (Reason: {item.reason})</>
                                )}
                                {Boolean(item.event && !isConfirmationCodeChanges) && (
                                    <> (Event: {remapStatus(item.event)})</>
                                )}
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
            </Space>

            <BookingLogs />

            {!loaded ? <Loader page /> : null}
        </Space>
    );
};

export default Booking;

import React, { useMemo } from 'react';
import { EntitySelector, useSelector } from 'common/entity-selector';
import apiMethods from 'core/methods';

const optionsGenerator = (list) =>
    list.map(({ code: value, name: label, marketId }) => ({
        value,
        label,
        marketId,
    }));

const AllCountriesSelector = ({ isWithoutMarket, marketId, ...props }) => {
    const { options, loading } = useSelector({ url: apiMethods.allCountries() }, optionsGenerator);

    const filteredOptions = useMemo(() => {
        if (isWithoutMarket) return options;
        if (!marketId) return [];
        return options.filter((item) => item.marketId === marketId);
    }, [marketId, options]);

    return <EntitySelector {...props} options={filteredOptions} loading={loading} />;
};

export default AllCountriesSelector;

import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { API } from 'htcore';
import sunpuMethods from 'core/sunpu-methods';
import { Menu, PageHeader } from 'antd';

import SupplierMapperUpdater from './supplier-tabs/supplier-mapper-updater';
import SupplierHistory from './supplier-tabs/supplier-history';
import SupplierCreationPage from './supplier-tabs/supplier-creation';
import SupplierDetailsPage from './supplier-tabs/supplier-details';
import SupplierContactsPage from './supplier-tabs/supplier-contacts';
import SupplierPaymentDetailsPage from './supplier-tabs/supplier-payment';
import OpenedAgenciesPage from './opened-agencies/opened-agencies-page';
import SupplierTechnicalDetailsPageWrap from './supplier-tabs/supplier-page-technical-details-routes';

import $auth from 'stores/auth';
import { remapStatus } from 'legacy';

const SupplierPageWrap = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [supplier, setSupplier] = useState(null);

    let { code } = useParams();
    if ('create' === code) {
        code = null;
    }

    const allMenuItems = [
        {
            label: <Link to="./">Supplier Details</Link>,
            key: '',
        },
        {
            label: <Link to="./contact">Contact Details</Link>,
            key: 'contact',
        },
        {
            label: <Link to="./payment">Payment</Link>,
            key: 'payment',
        },
        {
            label: <Link to="./updater">Connector Updater</Link>,
            key: 'updater',
            permissions: ['DataStaticManagement'],
        },
        {
            label: <Link to="./technical">Technical Details</Link>,
            key: 'technical',
            permissions: supplier?.supplierDetails?.contractType !== 'Offline',
        },
        {
            label: <Link to="./history">Change History</Link>,
            key: 'history',
        },
        {
            label: <Link to="./opened-agencies">Opened Agencies</Link>,
            key: 'opened-agencies',
        },
    ];

    const menuItems = allMenuItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    const loadSupplier = () => {
        if (code) {
            API.get({
                sunpu: sunpuMethods.supplierData(code),
                success: (data) => setSupplier(data),
            });
        }
    };

    useEffect(() => {
        loadSupplier();
    }, []);

    const isReadOnly = !$auth.permitted('SupplierManagement');

    return (
        <>
            <PageHeader
                onBack={() => navigate('../')}
                title={
                    supplier
                        ? `Supplier '${code}': ${supplier.supplierDetails.supplierName} (${remapStatus(
                              supplier.supplierDetails.status
                          )})`
                        : code
                        ? ' '
                        : 'Create New Supplier'
                }
            />
            {code ? (
                <>
                    <Menu
                        mode="horizontal"
                        items={menuItems}
                        selectedKeys={location.pathname.split('/')[3] || ''}
                    />
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <SupplierDetailsPage
                                    isReadOnly={isReadOnly}
                                    code={code}
                                    supplier={supplier?.supplierDetails}
                                    loadSupplier={loadSupplier}
                                />
                            }
                        />
                        <Route
                            path="/contact"
                            element={
                                <SupplierContactsPage
                                    isReadOnly={isReadOnly}
                                    code={code}
                                    supplier={supplier?.contactDetails}
                                    loadSupplier={loadSupplier}
                                />
                            }
                        />
                        <Route
                            path="/payment"
                            element={
                                <SupplierPaymentDetailsPage
                                    isReadOnly={isReadOnly}
                                    code={code}
                                    supplier={supplier?.payment}
                                    loadSupplier={loadSupplier}
                                />
                            }
                        />
                        <Route
                            path="/technical/*"
                            element={
                                <SupplierTechnicalDetailsPageWrap
                                    isReadOnly={isReadOnly}
                                    code={code}
                                    supplier={supplier}
                                    loadSupplier={loadSupplier}
                                />
                            }
                        />
                        <Route path="/updater" element={<SupplierMapperUpdater supplier={supplier} />} />
                        <Route path="/history" element={<SupplierHistory code={code} />} />
                        <Route
                            path="/opened-agencies/*"
                            element={<OpenedAgenciesPage supplier={supplier} />}
                        />
                    </Routes>
                </>
            ) : (
                <SupplierCreationPage />
            )}
        </>
    );
};

export default SupplierPageWrap;

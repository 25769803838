import React, { useEffect, useState } from 'react';
import apiMethods from 'core/methods';
import TablePages from 'components/table-pages';
import usePages from 'components/use-pages';
import { Alert, Form } from 'antd';
import { serializeRequest } from './serializer';
import TableSearchForm from './table-search-form';
import { columns } from './web-table-settings';
import { useParams } from 'react-router-dom';

const OpenedAgenciesWeb = () => {
    const [form] = Form.useForm();
    const [formValues, setFormValues] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [page, setPage] = usePages();

    const { code } = useParams();

    useEffect(() => {
        form.validateFields().then(setFormValues);
    }, []);

    const onSubmit = (values) => {
        setErrorMessage(null);
        setPage(1);
        setFormValues(values);
    };

    const handleRequestError = ([error]) => setErrorMessage(error.detail);

    return (
        <>
            <TableSearchForm searchForm={form} onSubmit={onSubmit} />

            {errorMessage ? (
                <Alert
                    description={errorMessage}
                    type="warning"
                    showIcon
                    style={{
                        margin: '0 20px 20px 0',
                        padding: 18,
                        borderColor: '#d9d9d9',
                        backgroundColor: '#d9d9d915',
                    }}
                />
            ) : (
                <TablePages
                    columns={columns}
                    formValues={formValues}
                    route={{ url: apiMethods.openedAgenciesWeb(code) }}
                    serializeRequest={(...rest) => serializeRequest(...rest, 'Id desc')}
                    page={page}
                    setPage={setPage}
                    handleRequestError={handleRequestError}
                />
            )}
        </>
    );
};

export default OpenedAgenciesWeb;

import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import BookingSearchForm from '../../booking-search-form';
import { serializeRequest } from '../../serializer';
import BookingsItineraryList from './bookings-itinerary-list';

const BookingsItinerary = ({ isSurrogate, searchForm, agentId, agencyId }) => {
    const [formValues, setFormValues] = useState(null);
    const [list, setList] = useState(null);

    useEffect(() => {
        searchForm.validateFields().then(setFormValues);
    }, []);

    const loadPage = () => {
        setList(null);
        API.get({
            url: apiMethods.bookingsList,
            body: {
                $filter: serializeRequest(
                    {
                        ...(formValues || {}),
                        ...(agencyId
                            ? {
                                  agencies: [{ value: agencyId, isSurrogate }],
                                  agencyType: isSurrogate ? 'surrogate' : 'active',
                              }
                            : {}),
                        ...(agentId ? { agentId } : {}),
                    },
                    null,
                    null,
                    true
                ).$filter,
                $orderBy: 'created asc',
            },
            success: (result) => {
                setList(result);
            },
        });
    };

    useEffect(() => {
        loadPage();
    }, [formValues]);

    const onSubmit = (values) => {
        setFormValues(values);
    };

    return (
        <>
            <BookingSearchForm
                bookingSearchForm={searchForm}
                onSubmit={onSubmit}
                isAgencyOrAgent={Boolean(agencyId || agentId)}
            />
            <BookingsItineraryList list={list} />
        </>
    );
};

export default BookingsItinerary;

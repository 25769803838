import React from 'react';
import AccountManagerSelector from 'components/selectors/account-manager-selector';
import { Button, Checkbox, Col, Form, Input, Row, Select, Space, DatePicker } from 'antd';
import { date } from 'legacy';

const { RangePicker } = DatePicker;

const AgencySearchForm = ({ isSurrogate, agencySearchForm, onSubmit }) => (
    <Form form={agencySearchForm} onFinish={onSubmit} layout="vertical" style={{ marginBottom: 30 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col lg={12} xl={6}>
                <Form.Item name="nameQuery" label="Name Query">
                    <Input placeholder="Part of name" allowClear />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="verificationState" label="Verification States">
                    <Select
                        mode="multiple"
                        placeholder="Any Verification State"
                        notFoundContent="Empty"
                        showArrow
                        allowClear
                        maxTagCount={2}
                        options={[
                            { value: 'PendingVerification', label: 'Pending Verification' },
                            ...(!isSurrogate
                                ? [
                                      { value: 'ReadOnly', label: 'Read Only' },
                                      { value: 'DeclinedVerification', label: 'Declined Verification' },
                                  ]
                                : []),
                            { value: 'FullAccess', label: 'Full Access' },
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="countryNameQuery" label="Country Name Query">
                    <Input placeholder="Part of Country Name" allowClear />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="cityQuery" label="City Name Query">
                    <Input placeholder="Part of City Name" allowClear />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="rangeCreation" label="Creation Date">
                    <RangePicker format={date.format.c} placeholder={['From', 'Till']} />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="accountManager" label="Account Manager">
                    <AccountManagerSelector placeholder="Account Manager" />
                </Form.Item>
            </Col>
            <Col lg={12} xl={6}>
                <Form.Item name="id" label="ID">
                    <Input placeholder="ID" allowClear />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Space direction="vertical" size="large">
                    {!isSurrogate && (
                        <Space>
                            <Form.Item noStyle name="isActive" valuePropName="checked">
                                <Checkbox>Hide Inactive Agencies</Checkbox>
                            </Form.Item>
                        </Space>
                    )}

                    <Space size="large">
                        <Button type="primary" htmlType="submit">
                            Search Agencies
                        </Button>
                        <Button
                            htmlType="button"
                            onClick={() => {
                                agencySearchForm.resetFields();
                                onSubmit({});
                            }}
                        >
                            Clear Filters
                        </Button>
                    </Space>
                </Space>
            </Col>
        </Row>
    </Form>
);

export default AgencySearchForm;

import React from 'react';
import { Col, Form, Row } from 'antd';
import AgencySelector from 'components/selectors/agency-selector';
import AgentSelector from 'components/selectors/agent-selector';

const FILTERS = {
    agencyId:
        "(ContractKind eq 'OfflineOrCreditCardPayments' or ContractKind eq 'VirtualAccountOrCreditCardPayments')",
};

const ActiveAgencyForm = ({ creation, getFieldValue, setFieldValue }) => (
    <>
        <Row size="large">
            <Col span={12} style={{ paddingRight: 30 }}>
                <Form.Item
                    name="agencyId"
                    label="Agency"
                    rules={[{ required: true, message: 'Please select' }]}
                >
                    <AgencySelector
                        disabled={!creation || !!getFieldValue('selectedItineraryNumber')}
                        filter={FILTERS.agencyId}
                        placeholder="Select"
                        onlyActiveAgencies
                        onChange={() => {
                            setFieldValue('agentId', []);
                            setFieldValue('customerCurrency', '');
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={12} style={{ paddingRight: 30 }}>
                {getFieldValue('agencyId') && (
                    <Form.Item
                        name="agentId"
                        label={`Agent in Agency ${getFieldValue('agencyId')}`}
                        key={getFieldValue('agentAgencyId')}
                        rules={[{ required: true, message: 'Please select' }]}
                    >
                        <AgentSelector agencyId={getFieldValue('agencyId')} placeholder="Select" />
                    </Form.Item>
                )}
            </Col>
        </Row>
    </>
);

export default ActiveAgencyForm;

import React, { useRef, useState } from 'react';
import { API } from 'htcore';
import { BOOKING_STATUS } from 'htcore/enum';
import { date } from 'legacy';
import { Button, Checkbox, Form } from 'antd';
import BookingSearchPage from '../../booking-search';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import $auth from 'stores/auth';

import apiMethods from 'core/methods';
import { notifications } from 'components/notification';

const AgencyOrAgentAllBookingsWithAutoCancellation = ({
    isSurrogate,
    agencyId,
    agentId,
    agency,
    bookingSearchForm,
}) => {
    const [form] = Form.useForm();
    const [isSaveModalVisible, setSaveModalVisibility] = useState(false);
    const bookingsOnPage = useRef({});

    const contractKind = agency?.contractKind;

    const isAutoCancellationManagementAvailable =
        (contractKind === 'VirtualAccountOrCreditCardPayments' ||
            contractKind === 'OfflineOrCreditCardPayments') &&
        $auth.permitted('BookingAutoCancellationManagement');

    const isAutoCancellationPossible = (booking) =>
        booking.status === BOOKING_STATUS.PendingCancellation ||
        booking.status === BOOKING_STATUS.ManualCorrectionNeeded ||
        (booking.status === BOOKING_STATUS.Confirmed && date.isFuture(booking.deadlineDate));

    const additionalColumns = ({ page }, filters) => {
        let isNewPage = false;

        const isEqualFilters = JSON.stringify(filters) === JSON.stringify(bookingsOnPage.current.filters);

        if (page !== bookingsOnPage.current.page || !isEqualFilters) {
            bookingsOnPage.current = { page, filters };
            isNewPage = true;
        }

        return [
            {
                header: 'No Auto Cancellation',
                cell: (cell) => {
                    const possible = isAutoCancellationPossible(cell);

                    if (isNewPage) {
                        form.setFieldsValue({
                            isAutoCancellationDisabled: {
                                ...form.getFieldValue('isAutoCancellationDisabled'),
                                [cell.referenceCode]: cell.isAutoCancellationDisabled,
                            },
                        });
                        if (possible) {
                            bookingsOnPage.current = {
                                ...bookingsOnPage.current,
                                [cell.referenceCode]: cell.isAutoCancellationDisabled,
                            };
                        }
                    }

                    return (
                        <>
                            <Form.Item
                                noStyle
                                name={['isAutoCancellationDisabled', cell.referenceCode]}
                                valuePropName="checked"
                            >
                                <Checkbox disabled={!possible} />
                            </Form.Item>
                            {Boolean(cell.autoCancellationDisabledBy?.trim()) && (
                                <span style={{ marginLeft: 8 }}>by {cell.autoCancellationDisabledBy}</span>
                            )}
                        </>
                    );
                },
            },
        ];
    };

    const getRequestBody = ({ isAutoCancellationDisabled }) => {
        let body = {};
        for (let i in isAutoCancellationDisabled) {
            if (
                isAutoCancellationDisabled[i] !== undefined &&
                i in bookingsOnPage.current &&
                isAutoCancellationDisabled[i] !== bookingsOnPage.current[i]
            )
                body[i] = isAutoCancellationDisabled[i];
        }
        return body;
    };

    const onButtonClick = () => {
        form.validateFields().then((values) => {
            const body = getRequestBody(values);
            if (Object.keys(body).some((key) => body[key])) {
                setSaveModalVisibility(Object.keys(body).filter((key) => body[key]).length);
            } else {
                setSaveModalVisibility(true);
            }
        });
    };

    const onAutoCancellationStatusesSubmit = () => {
        form.validateFields().then((values) => {
            const body = getRequestBody(values);
            API.post({
                url: apiMethods.autoCancellationStatuses(),
                body,
                success: () => {
                    notifications('Auto Cancellation Statuses Updated!', 'success');
                    setSaveModalVisibility(false);
                    bookingsOnPage.current = {
                        ...bookingsOnPage.current,
                        ...body,
                    };
                },
            });
        });
    };

    if (!isAutoCancellationManagementAvailable) {
        return (
            <BookingSearchPage agencyId={agencyId} agentId={agentId} bookingSearchForm={bookingSearchForm} />
        );
    }

    const TableWrapper = ({ children }) => (
        <Form layout="vertical" form={form} initialValues={{}}>
            {children}
        </Form>
    );

    return (
        <>
            <BookingSearchPage
                isSurrogate={isSurrogate}
                agencyId={agencyId}
                agentId={agentId}
                additionalColumns={additionalColumns}
                bookingSearchForm={bookingSearchForm}
                TableWrapper={TableWrapper}
            />
            <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button type="primary" onClick={onButtonClick}>
                    Save Auto Cancellation States Changes
                </Button>
            </div>
            <ConfirmationSimpleModal
                visible={isSaveModalVisible}
                onCancel={() => setSaveModalVisibility(false)}
                headerText="Are you sure?"
                submitText="Approve"
                onConfirm={onAutoCancellationStatusesSubmit}
            >
                {isSaveModalVisible === true ? (
                    <>You are going to enable auto-cancellation for some bookings,</>
                ) : (
                    <>
                        You are going to disable auto-cancellation for {isSaveModalVisible} booking
                        {isSaveModalVisible > 1 ? 's' : ''} for {agency?.name},
                    </>
                )}{' '}
                kindly ask you to approve your action
            </ConfirmationSimpleModal>
        </>
    );
};

export default AgencyOrAgentAllBookingsWithAutoCancellation;

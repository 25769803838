import React, { useState } from 'react';
import moment from 'moment';
import { date } from 'legacy';
import { Form, Typography, Row, Col, Input, Select, DatePicker, Checkbox } from 'antd';
import ServiceTypeSelector from 'components/selectors/service-type-selector';
import SupplierSelector from 'components/selectors/supplier-selector';
import AllCountriesSelector from 'components/selectors/all-countries-selector';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const requiredRule = { required: true, message: 'Please enter value' };

const BookingDetailsForm = ({
    creation,
    duplicate,
    deadlineDate,
    form,
    selectedBooking,
    passengersNumber,
    getBooking,
}) => {
    const [firstDate, setFirstDate] = useState(null);

    const onChangeRefCode = (event) => {
        const value = event.target.value;
        if (!!value) getBooking(value);
    };

    const getNights = (dates) => {
        if (dates) {
            const [dateFrom, dateTo] = dates;
            return dateTo.diff(dateFrom, 'days');
        }
        return 0;
    };

    const onCalendarChange = (value) => {
        setFirstDate(value?.[0] || value?.[1]);
    };

    const getIsBookingDates = (currentDate) => {
        const { checkInDate, checkOutDate } = selectedBooking?.bookingDetails || {};

        return (
            (checkInDate && currentDate.isBefore(moment(checkInDate))) ||
            (checkOutDate &&
                currentDate.isAfter(moment(checkOutDate).set({ hour: 23, minute: 59, seconds: 0 })))
        );
    };

    const disabledDate = (current) => {
        const currentDate = current.set({ hour: 0, minute: 0, seconds: 0 });

        if (getIsBookingDates(currentDate)) return true;

        const selectedDates = form.getFieldValue('rangeDates');
        const [checkIn, checkOut] = selectedDates || [];

        if ((checkIn && currentDate.isSame(checkIn)) || (checkOut && currentDate.isSame(checkOut))) {
            return true;
        }

        return !!(firstDate && currentDate.isSame(firstDate));
    };

    const disabledDeadlineDate = (current) => {
        const todayDate = moment().set({ hour: 0 });
        const checkIn = selectedBooking?.bookingDetails?.checkInDate;
        return (
            current.set({ hour: 23 }).isBefore(todayDate) ||
            (checkIn && current.isAfter(moment(checkIn).set({ hour: 23, minute: 59 })))
        );
    };

    return (
        <>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        name="type"
                        label="Service Type"
                        rules={[{ required: true, message: 'Please select' }]}
                    >
                        <ServiceTypeSelector onChange={(_, item) => form.setFieldValue('type', item)} />
                    </Form.Item>
                </Col>
            </Row>

            <Title level={4}>Booking Details</Title>

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="bookingReferenceCode" label="Booking Reference" rules={[requiredRule]}>
                        <Input
                            disabled={!creation && !duplicate}
                            placeholder="Enter Booking Reference"
                            onChange={onChangeRefCode}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item label="ITN-Reference number">
                        <Input disabled placeholder="-" value={selectedBooking?.itineraryNumber} />
                    </Form.Item>
                </Col>
            </Row>
            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item label="Agency">
                        <Input
                            disabled
                            placeholder="-"
                            value={selectedBooking?.agentInformation?.agencyName}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item label="Booked by Agent">
                        <Input
                            disabled
                            placeholder="-"
                            value={selectedBooking?.agentInformation?.agentName}
                        />
                    </Form.Item>
                </Col>
            </Row>

            {selectedBooking?.surrogateAgencyName && (
                <Row>
                    <Col span={12} style={{ paddingRight: 30 }}>
                        <Form.Item label="Surrogate Agency">
                            <Input disabled placeholder="-" value={selectedBooking.surrogateAgencyName} />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item noStyle shouldUpdate>
                        {({ setFieldValue }) => (
                            <Form.Item
                                name="contracted"
                                label="Supplier Type"
                                rules={[{ required: true, message: 'Please select' }]}
                            >
                                <Select
                                    placeholder="Select"
                                    onChange={(value) =>
                                        value === 'nonContracted' && setFieldValue('supplierCode', null)
                                    }
                                >
                                    <Option value="contracted">Contracted</Option>
                                    <Option value="nonContracted">Non-Contracted</Option>
                                </Select>
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.contracted !== currentValues.contracted
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('contracted') === 'contracted' ? (
                                <Form.Item
                                    name="supplierCode"
                                    label="Select a Supplier"
                                    rules={[{ required: true, message: 'Please select' }]}
                                >
                                    <SupplierSelector onlyEnabled />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>
                </Col>
            </Row>

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item label="Property Name">
                        <Input
                            disabled
                            placeholder="-"
                            value={selectedBooking?.bookingDetails?.accommodationName}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.rooms?.length !== currentValues.rooms?.length
                        }
                    >
                        {({ getFieldValue }) => (
                            <Form.Item label="Number of Rooms">
                                <Input disabled placeholder="-" value={getFieldValue('rooms')?.length} />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="rangeDates" label="Booking Dates" rules={[requiredRule]}>
                        <RangePicker
                            disabled={!selectedBooking}
                            format={date.format.c}
                            placeholder={['Check In', 'Check Out']}
                            disabledDate={disabledDate}
                            onCalendarChange={onCalendarChange}
                            onOpenChange={() => setFirstDate(null)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.rangeDates !== currentValues.rangeDates
                        }
                    >
                        {({ getFieldValue }) => (
                            <Form.Item label="Number of Nights">
                                <Input
                                    disabled
                                    value={getNights(getFieldValue('rangeDates'))}
                                    placeholder="Number of Nights"
                                />
                            </Form.Item>
                        )}
                    </Form.Item>
                </Col>
            </Row>

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) =>
                            getFieldValue('isNonRefundable') ? (
                                <Form.Item label="Deadline Date">
                                    <Input disabled value={date.format.c(deadlineDate || moment())} />
                                </Form.Item>
                            ) : (
                                <Form.Item name="deadlineDate" label="Deadline Date" rules={[requiredRule]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        disabledDate={disabledDeadlineDate}
                                        format={date.format.c}
                                        placeholder="Deadline"
                                    />
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="isNonRefundable" valuePropName="checked">
                        <Checkbox defaultChecked={false}>Non-Refundable</Checkbox>
                    </Form.Item>
                </Col>
            </Row>

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Row>
                        <Col span={12} style={{ paddingRight: 16 }}>
                            <Form.Item label="Adults">
                                <Input disabled placeholder="Adults" value={passengersNumber?.adults || 0} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Children">
                                <Input
                                    disabled
                                    placeholder="Children"
                                    value={passengersNumber?.children || 0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row size="large">
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="nationality" label="Nationality" rules={[requiredRule]}>
                        <AllCountriesSelector
                            isWithoutMarket
                            placeholder="Any Country"
                            onChange={(_, item) => form.setFieldValue('nationality', item)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12} style={{ paddingRight: 30 }}>
                    <Form.Item name="residency" label="Residency" rules={[requiredRule]}>
                        <AllCountriesSelector
                            isWithoutMarket
                            placeholder="Any Country"
                            onChange={(_, item) => form.setFieldValue('residency', item)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export default BookingDetailsForm;

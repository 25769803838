import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Loader } from 'legacy';
import { Button, Form } from 'antd';
import ConfirmationSimpleModal from 'components/confirms/confirmation-simple-modal';
import AgencyDetailsSurrogateForm from './agency-details-surrogate-form';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';

const mapDataToView = (data) => ({
    ...data,
    nonTRN: !data.taxRegistrationNumber,
});

const AgencyDetailsSurrogate = ({ agency, loadAgency }) => {
    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false);
    const [agencyDetailsValues, setAgencyDetailsValues] = useState({});

    const [isAutoCancellationDisabled, setIsAutoCancellationDisabled] = useState(null);

    const [agencyForm] = Form.useForm();

    const openSaveChangesModal = (values) => {
        setSaveChangesModalVisible(true);
        setAgencyDetailsValues(values);
    };

    const getAutoCancellationState = () => {
        API.get({
            url: apiMethods.agencySystemSettings(agency.id),
            success: ({ isBookingAutoCancellationDisabled }) => {
                agencyForm.setFieldValue(
                    'isBookingAutoCancellationDisabled',
                    isBookingAutoCancellationDisabled
                );

                setIsAutoCancellationDisabled(isBookingAutoCancellationDisabled);
            },
        });
    };

    useEffect(() => {
        getAutoCancellationState();
    }, []);

    const saveAutoCancellationState = (newValue) => {
        API.put({
            url: apiMethods.agencySystemSettings(agency.id),
            body: { isBookingAutoCancellationDisabled: newValue },
            success: () => {
                getAutoCancellationState();
                notifications('New Auto Cancellation Setting Saved!', 'success');
            },
        });
    };

    const submit = ({ isBookingAutoCancellationDisabled, ...values }) => {
        API.put({
            url: apiMethods.agency(agency.id),
            body: {
                ...agency,
                ...values,
                ...(values?.nonTRN && { taxRegistrationNumber: '' }),
            },
            success: (data) => {
                notifications('New Agency Data Saved!', 'success');
                loadAgency();
                agencyForm.setFieldsValue(mapDataToView(data));
            },
            after: () => {
                setSaveChangesModalVisible(false);
            },
        });

        if (isBookingAutoCancellationDisabled !== isAutoCancellationDisabled) {
            saveAutoCancellationState(isBookingAutoCancellationDisabled);
        }
    };

    if (!agency) {
        return <Loader />;
    }

    if (!$auth.permitted('AgencyManagement')) return null;

    return (
        <>
            <Form
                form={agencyForm}
                initialValues={mapDataToView(agency)}
                onFinish={openSaveChangesModal}
                layout="vertical"
            >
                <AgencyDetailsSurrogateForm />

                <Button htmlType="submit">Save Changes</Button>
            </Form>

            <ConfirmationSimpleModal
                visible={saveChangesModalVisible}
                onCancel={() => setSaveChangesModalVisible(false)}
                headerText="Confirmation"
                submitText="Confirm"
                onConfirm={() => submit(agencyDetailsValues)}
            >
                Are you sure that you want to save changes?
            </ConfirmationSimpleModal>
        </>
    );
};

export default AgencyDetailsSurrogate;

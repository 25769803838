import React from 'react';
import { EntitySelector } from 'common/entity-selector';

const agencyTypeOptions = [
    { label: 'All', value: 'all' },
    { label: 'Surrogate', value: 'surrogate' },
    { label: 'Active', value: 'active' },
];

const AgencyTypeSelector = ({ ...props }) => (
    <EntitySelector placeholder="Any Agency Type" {...props} options={agencyTypeOptions} />
);

export default AgencyTypeSelector;

import React, { useState } from 'react';
import { API, useTitle } from 'htcore';
import apiMaruokaMethods from 'core/maruoka-methods';
import { date } from 'legacy';
import { Form, message, PageHeader } from 'antd';
import RateComparisonForm from './rate-comparison-form';
import { notifications } from 'components/notification';
import RateComparisonHistory from './rate-comparison-history';

const mapDataToPayload = ({ accommodationHtId, rangeDates, nationality, supplierCodes }) => ({
    accommodationHtId: accommodationHtId.value,
    accommodationName: accommodationHtId.label,
    checkInDate: date.format.apiDateOnly(rangeDates[0]),
    checkOutDate: date.format.apiDateOnly(rangeDates[1]),
    nationality: nationality.value,
    supplierCodes,
});

const RateComparisonPage = () => {
    useTitle('Rate Comparison');

    const [isSearchStarted, setIsSearchStarted] = useState(false);
    const [searchResult, setSearchResult] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const [form] = Form.useForm();

    let intervalId;

    const pollSearchStatus = (searchId) => {
        intervalId = setInterval(() => {
            getSearchStatus(searchId);
        }, 3000);
    };

    const getSearchStatus = (searchId) => {
        API.get({
            maruoka: apiMaruokaMethods.searchStatus(searchId),
            success: ({ resultCount, status }) => {
                if (status) {
                    if (intervalId) clearInterval(intervalId);

                    setIsSearchStarted(false);

                    !resultCount ? setErrorMessage('No availability was found') : setSearchResult(searchId);
                } else if (!intervalId) {
                    pollSearchStatus(searchId);
                }
            },
            error: () => {
                if (intervalId) clearInterval(intervalId);
                setIsSearchStarted(false);
            },
        });
    };

    const resetPrevSearchData = () => {
        setSearchResult(null);
        setErrorMessage(null);
        intervalId && clearInterval(intervalId);
    };

    const startNewSearch = () => {
        form.resetFields();
        resetPrevSearchData();
    };

    const onSubmit = (values) => {
        setIsSearchStarted(true);
        resetPrevSearchData();

        API.post({
            maruoka: apiMaruokaMethods.searchStart,
            body: mapDataToPayload(values),
            success: getSearchStatus,
            error: () => {
                setIsSearchStarted(false);
            },
        });
    };

    const download = (res, hideMessage) => {
        if (res.status !== 200) {
            hideMessage();
            res.text().then((text) => {
                const errorDetail = JSON.parse(text)?.detail;
                errorDetail && notifications(errorDetail, 'error');
            });
        } else {
            const contentDisposition = res.headers.get('content-disposition');

            const fileName = contentDisposition
                ? contentDisposition.split('filename="')[1].split('";')[0]
                : 'file.csv';

            res.blob().then((blobby) => {
                let anchor = document.createElement('a');
                document.body.appendChild(anchor);

                const objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;

                anchor.download = fileName;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                hideMessage();
                message.success('Downloaded');
            });
        }
    };

    const downloadResults = (searchId) => {
        const hideMessage = message.loading(' Downloading...', 0);

        API.get({
            maruoka: apiMaruokaMethods.searchDownload(searchId),
            response: (result) => download(result, hideMessage),
        });
    };

    return (
        <>
            <PageHeader title="Rate Comparison" />
            <Form layout="vertical" form={form} onFinish={onSubmit} style={{ marginBottom: 40 }}>
                <RateComparisonForm
                    form={form}
                    loading={isSearchStarted}
                    errorMessage={errorMessage}
                    searchResult={searchResult}
                    downloadResults={downloadResults}
                    startNewSearch={startNewSearch}
                />
            </Form>
            <RateComparisonHistory searchResult={searchResult} download={downloadResults} />
        </>
    );
};

export default RateComparisonPage;

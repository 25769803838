import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Select } from 'antd';

const optionsGenerator = (list = []) => list.map(({ uHtId, name }) => ({ label: name, value: uHtId }));

const UnmappedAccommodationSelector = ({ setFieldValue, getFieldValue, ...props }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [empty, setEmpty] = useState(true);

    const getLabelById = (id) => {
        API.get({
            url: apiMethods.unmappedAccommodationSearchById(id),
            success: ({ uHtId, name }) => setFieldValue({ label: name, value: uHtId }),
        });
    };

    useEffect(() => {
        const unmappedHtId = getFieldValue();
        if (unmappedHtId?.value && !unmappedHtId?.label) getLabelById(unmappedHtId.value);
    }, []);

    const onSearch = (value) => {
        if (value) {
            API.get({
                url: apiMethods.unmappedAccommodationSearch(value),
                success: (data) => {
                    setOptions(optionsGenerator(data));
                    setEmpty(false);
                },
                after: () => setLoading(false),
            });
        } else {
            setOptions([]);
            setLoading(false);
            setEmpty(true);
        }
    };

    const onBlur = (event) => {
        const inputValue = event.target.value;

        const currentValue = getFieldValue();

        if (inputValue && !options.length) {
            setFieldValue({ label: inputValue, value: null });
            return;
        }
        if (!currentValue?.value) {
            setFieldValue(null);
        }
    };

    return (
        <Select
            showSearch
            filterOption={false}
            onSearch={onSearch}
            showArrow
            options={options}
            loading={loading}
            onBlur={onBlur}
            notFoundContent={empty ? null : 'Nothing found'}
            allowClear
            {...props}
        />
    );
};

export default UnmappedAccommodationSelector;

import React from 'react';
import { Form } from 'antd';
import { getTypeFieldName } from 'legacy/excluded-form-utils';
import { EntitySelector } from 'common/entity-selector';

export const typesOptions = [
    { label: 'Include', value: 'include' },
    { label: 'Except', value: 'except' },
];

const TypeSelector = ({ name, isGeneratedName = true, ...props }) => (
    <Form.Item noStyle shouldUpdate>
        {({ validateFields }) => (
            <Form.Item
                name={isGeneratedName ? getTypeFieldName(name) : name}
                label="Type"
                style={{ marginRight: 15 }}
                {...props}
            >
                <EntitySelector
                    allowClear={false}
                    options={typesOptions}
                    onChange={() => validateFields([name])}
                />
            </Form.Item>
        )}
    </Form.Item>
);

export default TypeSelector;

import React from 'react';
import { Col, Form, Row, Space, Switch, Typography } from 'antd';
import AgencyDetailsForm from './agency-details-form';
import AccountManagerSelector from 'components/selectors/account-manager-selector';

const { Title } = Typography;

const AgencyDetailsSurrogateForm = ({ isCreation }) => (
    <Row style={{ width: '100%', justifyContent: 'left' }}>
        <Col span={12}>
            <AgencyDetailsForm isSurrogate isEdit={!isCreation} />
        </Col>
        <Col span={6} style={{ marginLeft: 40 }}>
            <Title level={4} style={{ margin: '0 0 30px' }}>
                Account Manager
            </Title>

            <Form.Item name="accountManagerId" label="Account Manager">
                <AccountManagerSelector placeholder="Account Manager" />
            </Form.Item>

            {!isCreation && (
                <Space style={{ alignItems: 'baseline' }}>
                    <span>No Auto-Cancellation</span>
                    <Form.Item name="isBookingAutoCancellationDisabled" valuePropName="checked">
                        <Switch style={{ marginLeft: 10 }} />
                    </Form.Item>
                </Space>
            )}
        </Col>
    </Row>
);

export default AgencyDetailsSurrogateForm;

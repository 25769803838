import React from 'react';
import { API } from 'htcore';
import { useNavigate } from 'react-router-dom';
import apiMethods from 'core/methods';
import { Button, Form, Input } from 'antd';
import { notifications } from 'components/notification';

const safeString = (val) => val.replace(/'/g, "''");

const FindBooking = () => {
    const navigate = useNavigate();
    const [findBookingForm] = Form.useForm();

    const navigateToBooking = (refCode) => navigate(`/booking/${refCode}`);

    const getBookingByRefCode = (refCode) => {
        API.get({
            url: refCode.includes('MPS')
                ? apiMethods.serviceByReferenceCode(refCode)
                : apiMethods.bookingsByReferenceCode(refCode),
            success: () => navigateToBooking(refCode),
        });
    };

    const getBookingBySupplierRefCode = (supplierRefCode) => {
        API.get({
            url: apiMethods.bookingsList,
            body: {
                $filter: `tolower(supplierReferenceCode) eq tolower('${safeString(supplierRefCode)}')`,
                $orderBy: 'Created desc',
                $top: 10,
                $skip: 0,
            },
            success: (data) => {
                if (data?.length > 0) {
                    navigateToBooking(data[0].referenceCode);

                    if (data?.length > 1) {
                        notifications('Careful! More than one booking found!');
                    }
                } else {
                    notifications('Booking not found!');
                }
            },
            error: () => {
                notifications('Booking not found!');
            },
        });
    };

    const getBookingByInvoiceNumber = (number) => {
        API.get({
            url: apiMethods.bookingByInvoiceNumber(number),
            success: (refCode) => navigateToBooking(refCode),
        });
    };

    const submit = (values) => {
        const refCode = String(values.refCode).toUpperCase();
        const supplierRefCode = values.supplierRefCode;

        if (supplierRefCode) {
            getBookingBySupplierRefCode(supplierRefCode);
            return;
        }

        if (values.invoiceNumber) {
            getBookingByInvoiceNumber(values.invoiceNumber);
            return;
        }

        getBookingByRefCode(refCode);
    };

    return (
        <Form form={findBookingForm} onFinish={submit} layout="vertical" style={{ maxWidth: 500 }}>
            <Form.Item name="refCode" label="Reference code / Itinerary Number">
                <Input placeholder="HTL-NYC-AE-2311T0N1N / ITN-0606-00001" />
            </Form.Item>
            <Form.Item name="supplierRefCode" label="Supplier Reference code">
                <Input placeholder="REF-SUP-1234" />
            </Form.Item>
            <Form.Item name="invoiceNumber" label="Invoice Number">
                <Input placeholder="INV-25914-01/2024" />
            </Form.Item>
            <Button htmlType="submit">Search</Button>
        </Form>
    );
};

export default FindBooking;

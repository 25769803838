import React, { useState } from 'react';
import { Card, Space } from 'antd';
import { InfoCircle } from 'iconsax-react';
import { date } from '../../legacy';

const DeadlineShift = ({ agency }) => {
    if (agency.contractKind !== 'OfflineOrCreditCardPayments') {
        return null;
    }

    const [isExpanded, setExpansion] = useState(false);

    const exampleDay = new Date('01/01/2024');

    const agencyBase = date.addDay(exampleDay, -2);

    return (
        <div className="ant-form-vertical" style={{ margin: '10px 0 25px' }}>
            <Space
                size="large"
                style={{
                    alignItems: 'end',
                }}
            >
                <div>Deadline shift: 2 days (default)</div>
                <a onClick={() => setExpansion((v) => !v)} style={{ display: 'block' }}>
                    <InfoCircle style={{ verticalAlign: 'middle', transform: 'rotate(180deg)' }} />{' '}
                    {isExpanded ? 'Hide' : 'Show'} Example
                </a>
                <Card style={{ margin: '-40px 0 -40px 15px' }} hidden={!isExpanded}>
                    <table>
                        <tr>
                            <td>Supplier Deadline</td>
                            <td style={{ paddingLeft: 30 }}>
                                {date.format.shortDayInverted(exampleDay)}, 15:00
                            </td>
                        </tr>
                        <tr>
                            <td>Agency Deadline</td>
                            <td style={{ paddingLeft: 30 }}>
                                {date.format.shortDayInverted(agencyBase)}, 15:00
                            </td>
                        </tr>
                    </table>
                </Card>
            </Space>
        </div>
    );
};

export default DeadlineShift;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'htcore';
import { Button, Form, PageHeader, Select, Space } from 'antd';
import Table from 'components/table';
import LightDropdown from 'components/light-dropdown';
import sunpuMethods from 'core/sunpu-methods';
import { notifications } from 'components/notification';
import $auth from 'stores/auth';
import { remapStatus } from '../../legacy';

const priority = ['Enabled', 'TestOnly', 'Disabled'];

const SuppliersListPage = () => {
    const [suppliers, setSuppliers] = useState(null);
    const [channelTypeFilter, setChannelTypeFilter] = useState(null);
    const [supplierTypeFilter, setSupplierTypeFilter] = useState(null);

    const loadSuppliers = () => {
        API.get({
            sunpu: sunpuMethods.suppliersData,
            success: (data) => {
                setSuppliers(
                    data
                        .sort(
                            (a, b) =>
                                Number(b.supplierDetails.supplierId.slice(3)) -
                                Number(a.supplierDetails.supplierId.slice(3))
                        )
                        .sort(
                            (a, b) =>
                                priority.indexOf(a.supplierDetails.status) -
                                priority.indexOf(b.supplierDetails.status)
                        )
                );
            },
        });
    };

    const onChangeType = (code, cell, type) => {
        return new Promise((resolve) => {
            API.post({
                sunpu: sunpuMethods.supplierTechnicalDetails(code),
                body: { ...cell, supplierTechnicalDetails: type },
                error: () => {
                    resolve(false);
                },
                success: () => {
                    resolve(true);
                    notifications('Supplier Type Saved!', 'success');
                },
            });
        });
    };

    useEffect(() => {
        loadSuppliers();
    }, []);

    let suppliersToShow = suppliers;

    if (channelTypeFilter !== null) {
        suppliersToShow = suppliersToShow.filter(
            ({ technicalDetails }) => technicalDetails.channelType === channelTypeFilter
        );
    }
    if (supplierTypeFilter !== null) {
        suppliersToShow = suppliersToShow.filter(
            ({ technicalDetails }) => technicalDetails.supplierType === supplierTypeFilter
        );
    }

    return (
        <>
            <PageHeader
                title="Suppliers/Channels List"
                extra={
                    <Space>
                        <Form layout="vertical" style={{ marginTop: 10 }}>
                            <Space>
                                <Form.Item label="Channel Type" style={{ width: 180 }}>
                                    <Select
                                        placeholder="All Channel Types"
                                        onChange={(v) => setChannelTypeFilter(v)}
                                    >
                                        <Option value={null}>All Channel Types</Option>
                                        <Option value="NotApplicable">N/A</Option>
                                        <Option value="SWITCH">SWITCH</Option>
                                        <Option value="DirectConnect">Direct Connect</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Supplier Type" style={{ width: 180 }}>
                                    <Select
                                        placeholder="All Supplier Types"
                                        onChange={(v) => setSupplierTypeFilter(v)}
                                    >
                                        <Option value={null}>All Supplier Types</Option>
                                        <Option value="NotSpecified">None</Option>
                                        <Option value="StaticContract">Static Contract</Option>
                                        <Option value="DirectConnectivity">Direct Connectivity</Option>
                                        <Option value="ChannelManager">Channel Manager</Option>
                                        <Option value="Party">3rd Party</Option>
                                    </Select>
                                </Form.Item>
                            </Space>
                        </Form>
                        {$auth.permitted('SupplierManagement') && (
                            <Link to="./create">
                                <Button type="primary">Add New Supplier</Button>
                            </Link>
                        )}
                    </Space>
                }
                style={{ paddingBottom: 16, paddingTop: 0 }}
            />
            <Table
                list={suppliersToShow}
                columns={[
                    {
                        header: <>Supplier&nbsp;ID</>,
                        cell: ({ supplierDetails }) => supplierDetails.supplierId,
                    },
                    {
                        header: 'Contract Type',
                        cell: ({ supplierDetails }) =>
                            supplierDetails.contractType === 'OnlineAndOffline'
                                ? 'Online / Offline'
                                : remapStatus(supplierDetails.contractType),
                    },
                    {
                        header: 'Code',
                        cell: ({ supplierDetails }) => (
                            <Link to={`/suppliers/${supplierDetails.supplierCode}`}>
                                {supplierDetails.supplierCode}
                            </Link>
                        ),
                    },
                    {
                        header: 'GIATA Code',
                        cell: ({ technicalDetails: cell }) =>
                            cell.giataSupplierCode ? cell.giataSupplierCode : '-',
                    },
                    {
                        header: 'Vervotech Code',
                        cell: ({ technicalDetails: cell }) =>
                            cell.vervotechSupplierCode ? cell.vervotechSupplierCode : '-',
                    },
                    {
                        header: 'Supplier Name',
                        cell: ({ supplierDetails }) => (
                            <>
                                <Link to={`/suppliers/${supplierDetails.supplierCode}`}>
                                    {supplierDetails.supplierName}
                                </Link>
                            </>
                        ),
                    },
                    {
                        header: 'Connector Name',
                        cell: ({ technicalDetails }) =>
                            technicalDetails.connectorUrl?.split('/')?.[2]?.replace('.happytravel.com', '') ||
                            '-',
                    },
                    {
                        header: 'Channel Type',
                        cell: ({ technicalDetails }) =>
                            technicalDetails.channelType === 'SWITCH'
                                ? 'SWITCH'
                                : remapStatus(technicalDetails.channelType),
                    },
                    {
                        header: 'Supplier Type / Channel',
                        cell: ({ supplierDetails, technicalDetails: cell }) => {
                            return (
                                <LightDropdown
                                    items={[
                                        { key: '', label: '—' },
                                        { key: 'StaticContract', label: 'Static Contract' },
                                        { key: 'DirectConnectivity', label: 'Direct Connectivity' },
                                        { key: 'ChannelManager', label: 'Channel Manager' },
                                        { key: 'Party', label: '3rd Party' },
                                    ]}
                                    initialValue={cell.supplierType || '—'}
                                    onSelectCallback={(type) =>
                                        onChangeType(supplierDetails.supplierCode, cell, type)
                                    }
                                />
                            );
                        },
                    },
                    {
                        header: 'Status',
                        cell: ({ supplierDetails }) => <>{supplierDetails.status}</>,
                    },
                    {
                        header: 'Multi-Room',
                        cell: ({ technicalDetails: cell }) => (cell.isMultiRoomFlowSupported ? 'Yes' : '–'),
                    },
                    {
                        header: 'VCC',
                        cell: ({ technicalDetails: cell }) => (cell.canUseVcc ? 'Yes' : '–'),
                    },
                    {
                        header: 'Cron',
                        cell: ({ technicalDetails: cell }) =>
                            cell.connectorUpdaterCronExpression ? 'Yes' : '–',
                    },
                ]}
                textEmptyResult="Empty"
                rowKey={({ supplierDetails }) => supplierDetails.supplierId}
            />
        </>
    );
};

export default SuppliersListPage;

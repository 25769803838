const safeString = (val) => val.replace(/'/g, "''");

const activityValues = {
    Active: true,
    Inactive: false,
};

export const serializeRequest = (values, top, skip, sorting) => {
    const filters = [];

    if (values.agencyId) {
        filters.push(`Id eq ${values.agencyId}`);
    }

    if (values.agencyName) {
        filters.push(`contains(tolower(agencyName),tolower('${safeString(values.agencyName)}'))`);
    }

    if (values.supplierSetting) {
        if (values.supplierSetting !== 'All') {
            filters.push(`IsSupplierEnabled eq ${activityValues[values.supplierSetting]}`);
        }
    }

    return {
        ...(filters.length
            ? {
                  $filter: filters.join(' and '),
              }
            : {}),
        $orderBy: sorting,
        $top: top,
        $skip: skip,
    };
};

import React, { useState } from 'react';
import { remapStatus } from 'legacy';
import { Checkbox, Col, Form, Input, InputNumber, Row, Space, Typography } from 'antd';
import LocalitySelector from 'components/selectors/locality-selector';
import MarketSelector from 'components/selectors/market-selector';
import AllCountriesSelector from 'components/selectors/all-countries-selector';
import AgencyLocalitySelector from '../agency-locality-selector';

const { Title } = Typography;

const AgencyDetailsForm = ({ isEdit, isSurrogate, agency }) => {
    const [selectedLocality, setSelectedLocality] = useState('');

    return (
        <Row gutter={40}>
            <Col span={12}>
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Agency Details
                </Title>

                <Form.Item name="name" label="Name" rules={isSurrogate && [{ required: true }]}>
                    <Input disabled={isEdit} placeholder="Name" />
                </Form.Item>
                <Form.Item name="localityHtId" label="Locality" rules={isSurrogate && [{ required: true }]}>
                    <LocalitySelector setSelectedLabel={setSelectedLocality} />
                </Form.Item>
                <Form.Item name="address" label="Address" rules={isSurrogate && [{ required: true }]}>
                    <Input placeholder="Address" />
                </Form.Item>
                <Form.Item
                    name="legalAddress"
                    label="Legal Address"
                    rules={isSurrogate && [{ required: true }]}
                >
                    <Input placeholder="Legal Address" />
                </Form.Item>
                <Form.Item name="postalCode" label="Postal Code">
                    <Input placeholder="Postal Code" />
                </Form.Item>
                <Space>
                    <Form.Item name="phone" label="Phone" rules={isSurrogate && [{ required: true }]}>
                        <InputNumber
                            type="number"
                            prefix="+"
                            placeholder="Phone"
                            style={{ width: '100% ' }}
                            controls={false}
                        />
                    </Form.Item>
                    <Form.Item name="fax" label="Fax">
                        <InputNumber
                            type="number"
                            prefix="+"
                            placeholder="Fax"
                            style={{ width: '100% ' }}
                            controls={false}
                        />
                    </Form.Item>
                </Space>
                <Form.Item name="website" label="Website">
                    <Input placeholder="Website" />
                </Form.Item>
                <Form.Item name="vatNumber" label="Vat Number">
                    <Input placeholder="Vat Number" />
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.localityHtId !== currentValues.localityHtId ||
                        prevValues.nonTRN !== currentValues.nonTRN
                    }
                >
                    {({ getFieldValue, setFieldValue }) =>
                        selectedLocality?.includes('United Arab Emirates') && (
                            <>
                                <div style={{ marginBottom: 10 }}>
                                    <Form.Item noStyle name="nonTRN" valuePropName="checked">
                                        <Checkbox
                                            onChange={(event) => {
                                                event.target.checked &&
                                                    setFieldValue('taxRegistrationNumber', '');
                                            }}
                                        >
                                            Non VAT Registered Agency
                                        </Checkbox>
                                    </Form.Item>
                                </div>
                                {!getFieldValue('nonTRN') && (
                                    <Form.Item
                                        name="taxRegistrationNumber"
                                        label="TRN"
                                        rules={[
                                            { required: true },
                                            { min: 15, max: 15, message: 'TRN is 15 digits long' },
                                        ]}
                                    >
                                        <Input maxLength={15} placeholder="Tax Registration Number" />
                                    </Form.Item>
                                )}
                            </>
                        )
                    }
                </Form.Item>
                {isSurrogate ? (
                    <>
                        <Form.Item
                            name={['contactInfo', 'email']}
                            label="Email"
                            rules={[
                                { type: 'email', message: 'Please enter a valid email address.' },
                                { required: true },
                            ]}
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                    </>
                ) : (
                    <Form.Item
                        name="notificationEmail"
                        label="Email For Notifications"
                        rules={[{ type: 'email', message: 'Please enter a valid email address.' }]}
                    >
                        <Input placeholder="Email For Agency Notifications" />
                    </Form.Item>
                )}

                <Form.Item name="billingEmail" label="Billing Email" rules={[{ required: true }]}>
                    <Input placeholder="Billing Email" />
                </Form.Item>
                <Form.Item name="remarks" label="Remarks">
                    <Input.TextArea
                        placeholder="This is a possibility to left an internal remark optionally. It will only be visible here for admins."
                        style={{ minHeight: '10em', maxHeight: '10em' }}
                        maxLength={500}
                    />
                </Form.Item>
                {!isSurrogate && (
                    <div style={{ margin: '20px 0 20px' }}>
                        Preferred Payment Method:{' '}
                        <b>{remapStatus(agency?.preferredPaymentMethod || 'Unknown')}</b>
                    </div>
                )}
            </Col>

            <Col span={12}>
                <Title level={4} style={{ margin: '0 0 30px' }}>
                    Sales Market
                </Title>

                <Form.Item shouldUpdate noStyle>
                    {({ setFieldValue }) => (
                        <Form.Item
                            name="salesMarketId"
                            label="Market"
                            rules={[{ required: true, message: 'Please Enter Market' }]}
                        >
                            <MarketSelector
                                placeholder="Market"
                                onChange={() => {
                                    setFieldValue('salesCountryCode', null);
                                    setFieldValue('salesLocalityHtId', null);
                                }}
                            />
                        </Form.Item>
                    )}
                </Form.Item>

                <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.salesMarketId !== currentValues.salesMarketId
                    }
                    noStyle
                >
                    {({ getFieldValue, setFieldValue }) => (
                        <Form.Item
                            name="salesCountryCode"
                            label="Country"
                            rules={[{ required: true, message: 'Please Enter Country' }]}
                        >
                            <AllCountriesSelector
                                placeholder="Country"
                                marketId={getFieldValue('salesMarketId')}
                                onChange={() => setFieldValue('salesLocalityHtId', null)}
                            />
                        </Form.Item>
                    )}
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.salesCountryCode !== currentValues.salesCountryCode
                    }
                >
                    {({ getFieldValue }) => (
                        <Form.Item
                            name="salesLocalityHtId"
                            label="Locality"
                            rules={[{ required: true, message: 'Please Enter Locality' }]}
                        >
                            <AgencyLocalitySelector countryId={getFieldValue('salesCountryCode')} />
                        </Form.Item>
                    )}
                </Form.Item>
            </Col>
        </Row>
    );
};

export default AgencyDetailsForm;

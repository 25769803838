import React, { useEffect, useState } from 'react';
import { API } from 'htcore';
import apiMethods from 'core/methods';
import { Space, Switch, Typography } from 'antd';
import ConfirmationModal from 'components/confirms/confirmation-modal';

const { Title } = Typography;

const RoomMappingSettings = () => {
    const [isToggled, setIsToggled] = useState();
    const [isOpenConfirm, setIsOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(false);

    const getSwitchState = () => {
        setLoading(true);
        API.get({
            url: apiMethods.roomMappingSettings,
            success: ({ isEnabled }) => setIsToggled(isEnabled),
            after: () => setLoading(false),
        });
    };

    useEffect(() => {
        getSwitchState();
    }, []);

    const onToggle = ({ reason }) => {
        setIsOpenConfirm(false);
        setLoading(true);

        const newState = !isToggled;

        return API.post({
            url: apiMethods.roomMappingSettings,
            body: { isEnabled: newState, reason },
            success: () => setIsToggled(newState),
            after: () => setLoading(false),
        });
    };

    return (
        <>
            <Space size="large">
                <div style={{ marginRight: 60 }}>
                    <Title level={4}>Room Mapping</Title>
                    <p style={{ color: '#777777' }}>
                        If it’s disabled, we don’t group the search results even if the room mapping is
                        enabled on the Agent level.
                    </p>
                </div>
                <Switch loading={loading} checked={isToggled} onChange={() => setIsOpenConfirm(true)} />
            </Space>

            {isOpenConfirm && (
                <ConfirmationModal
                    visible={isOpenConfirm}
                    onCancel={() => setIsOpenConfirm(false)}
                    headerText="The setting will be changed. Do you really wish to process?"
                    submitText="Confirm"
                    onSubmit={onToggle}
                />
            )}
        </>
    );
};

export default RoomMappingSettings;

import React, { useState } from 'react';
import { message, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { API } from 'htcore';
import apiMethods from 'core/methods';

const MarkupActivitySwitcher = ({ initialValue, markupId, updateData }) => {
    const [state, setState] = useState(initialValue);
    const [loading, setLoading] = useState(false);

    const toggleActivation = (value) => {
        setLoading(true);
        API.post({
            url: value ? apiMethods.markupActivate(markupId) : apiMethods.markupDeactivate(markupId),
            success: () => {
                setState(!state);
                updateData(markupId);
                if (value) {
                    message.success('Markup #' + markupId + ' activated');
                } else {
                    message.warn('Markup #' + markupId + ' disabled');
                }
            },
            after: () => {
                setLoading(false);
            },
        });
    };

    return (
        <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={state}
            loading={loading}
            onChange={toggleActivation}
        />
    );
};

export default MarkupActivitySwitcher;

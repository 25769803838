import React, { useEffect, useState } from 'react';
import { Loader } from 'legacy';
import { Checkbox, Form, Input, Row, Table, Col, Space } from 'antd';
import TypeSelector from 'components/selectors/type-selector';
import AgencyLocalitySelector from '../../agency/agency-parts/agency-locality-selector';

const columns = (tableTitle, countryId, onChangeSearch) => [
    {
        title: tableTitle,
        dataIndex: 'name',
        key: 'name',
        children: [
            {
                title: () => <Input placeholder="Search" onChange={onChangeSearch} />,
                render: (cell, data, index) => (
                    <Row gutter={20} align="middle">
                        <Col span={7}>
                            <Form.Item noStyle shouldUpdate>
                                {({ setFieldValue }) => (
                                    <Space>
                                        <Form.Item
                                            noStyle
                                            name={[index, 'isAvailable']}
                                            valuePropName="checked"
                                        >
                                            <Checkbox
                                                onChange={() => setFieldValue([index, 'localityHtIds'], [])}
                                            />
                                        </Form.Item>
                                        <span>{cell?.supplierCode}</span>
                                    </Space>
                                )}
                            </Form.Item>
                        </Col>

                        <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) =>
                                getFieldValue([index, 'isAvailable']) && (
                                    <>
                                        <Col span={5}>
                                            <TypeSelector
                                                isGeneratedName={false}
                                                name={[index, 'type']}
                                                style={{ marginBottom: 0 }}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name={[index, 'localityHtIds']}
                                                label="Locality"
                                                style={{ marginBottom: 0 }}
                                            >
                                                <AgencyLocalitySelector
                                                    placeholder="All"
                                                    multiple
                                                    countryId={countryId}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </>
                                )
                            }
                        </Form.Item>
                    </Row>
                ),
            },
        ],
    },
];

const SupplierLocalitiesTable = ({ id, suppliersList, suppliersLoading, form }) => {
    const [filteredList, setFilteredList] = useState(null);

    useEffect(() => {
        setFilteredList(suppliersList);
    }, [suppliersList]);

    const onChangeSearch = (event) => {
        const { value } = event.target;
        const newList = suppliersList.filter(({ supplierCode }) =>
            supplierCode.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredList(newList);
    };

    return (
        <Form layout="vertical" form={form}>
            <Table
                pagination={false}
                rowKey="id"
                columns={columns(`Supplier List (${suppliersList?.length || 0})`, id, onChangeSearch)}
                dataSource={filteredList}
                loading={suppliersLoading}
                locale={{
                    emptyText: suppliersLoading ? (
                        <Loader />
                    ) : (
                        <b style={{ color: '#231f20' }}>Please Select Location First</b>
                    ),
                }}
            />
        </Form>
    );
};

export default SupplierLocalitiesTable;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTitle } from 'htcore';
import apiMethods from 'core/methods';
import { Button, PageHeader } from 'antd';
import usePages from 'components/use-pages';
import TablePages from 'components/table-pages';
import { serializeRequest } from './serializer';
import AgencySearchForm from './agency-search-form';
import { columns } from '../agency-parts/agencies-table-settings';
import $auth from 'stores/auth';

const AgenciesList = ({ isSurrogate, agencySearchForm }) => {
    useTitle('Agencies');

    const location = useLocation();

    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();

    useEffect(() => {
        agencySearchForm.validateFields().then((values) => setFormValues({ ...values, isSurrogate }));
    }, [location.pathname]);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues({ ...values, isSurrogate });
    };

    return (
        <>
            <AgencySearchForm
                isSurrogate={isSurrogate}
                agencySearchForm={agencySearchForm}
                onSubmit={onSubmit}
            />

            <PageHeader
                title="Agencies List"
                extra={
                    $auth.permitted('AddAgency') && isSurrogate ? (
                        <Link to="/surrogate-agencies/create-surrogate-agency">
                            <Button type="primary">Add New Surrogate Agency</Button>
                        </Link>
                    ) : null
                }
                style={{ paddingBottom: 16, paddingTop: 0 }}
            />
            <TablePages
                columns={(navigationState) => columns(navigationState, isSurrogate)}
                formValues={formValues}
                route={{ url: apiMethods.agencies }}
                serializeRequest={serializeRequest}
                page={page}
                setPage={setPage}
            />
        </>
    );
};

export default AgenciesList;

import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import SupplierTechnicalDetails from './supplier-technical-details';

import $auth from 'stores/auth';
import SupplierCachePage from './supplier-cache';

const SupplierTechnicalDetailsPageWrap = ({ isReadOnly, code, supplier, loadSupplier }) => {
    const location = useLocation();

    const allMenuItems = [
        {
            label: <Link to="./">Technical Details</Link>,
            key: '',
        },
        {
            label: <Link to="./cache">Cache</Link>,
            key: 'cache',
        },
    ];

    const menuItems = allMenuItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    return (
        <>
            <Menu
                mode="horizontal"
                items={menuItems}
                selectedKeys={location.pathname.split('/')[4] || ''}
                style={{ marginTop: -20 }}
            />
            <Routes>
                <Route
                    path="/"
                    element={
                        <SupplierTechnicalDetails
                            isReadOnly={isReadOnly}
                            code={code}
                            supplier={supplier?.technicalDetails}
                            loadSupplier={loadSupplier}
                        />
                    }
                />
                <Route
                    path="/cache"
                    element={
                        <SupplierCachePage
                            isReadOnly={isReadOnly}
                            code={code}
                            supplier={supplier}
                            loadSupplier={loadSupplier}
                        />
                    }
                />
            </Routes>
        </>
    );
};

export default SupplierTechnicalDetailsPageWrap;
